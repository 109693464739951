import { createEntityAdapter } from '@reduxjs/toolkit';
import { IEppDamper } from 'application/components/api/EppDamper';

export const eppDamperAdapter = createEntityAdapter<IEppDamper, number>({
  selectId: (project) => project.eppDamperID,
  sortComparer: (a, b) => {
    let res = a.eppDamperTypeID - b.eppDamperTypeID;
    if (res === 0) res = a.eppDamperID - b.eppDamperID;
    return res;
  },
});
