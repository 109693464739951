import { IValveConnectorEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorEdit';
import React from 'react';
import { IGridStatePlaceHolder } from '../../controls/hooks/useGridStateData';
import Td from '../../controls/table/Td';
import { IGridStateData } from '../ValveConnectorGrid';
import ConnectorGridImage from './ConnectorGridImage';
import { GridInputTd } from '../../controls/table/GridInputTd';
import { datatype } from '../../controls/input/GridInput';
import { IUsesKeyboardNavigation } from '../../controls/hooks/useKeyboardNavigation';
import { IValveGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveGridEdit';
import {
  IValveConnectorGridEdit,
  updateValveConnectorGridsEdit,
} from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme: Theme) => ({
  cell: {
    minWidth: 50,
  },
}));

const ValveGridCell = (props: {
  state: IGridStatePlaceHolder<IGridStateData>;
  valveGrid: IValveGridEdit;
  valveConnector: IValveConnectorEdit;
  valveConnectorGrids: IValveConnectorGridEdit[];
  valveConnectorGridUpdated: (ValveConnectorGridEdit: IValveConnectorGridEdit) => any;
  keyboardNavigation: IUsesKeyboardNavigation;
}) => {
  const classes = styles();
  const valveConnectorGrid = props.valveConnectorGrids.find(
    (a) => a.valveConnectorID === props.valveConnector.valveConnectorID && a.valveGridID === props.valveGrid.valveGridID
  );

  if (valveConnectorGrid) {
    return (
      <Td>
        <table>
          <thead>
            <tr>
              <th />
              <th>zeta</th>
              <th>duplex</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>S</td>
              <GridInputTd
                tdClassName={classes.cell}
                value={valveConnectorGrid?.supplyZeta}
                row={valveConnectorGrid}
                onCommit={(row: any, name: string, value: any) => {
                  updateValveConnectorGridsEdit({ ...valveConnectorGrid, supplyZeta: value }).then((result) => {
                    props.valveConnectorGridUpdated(result);
                  });
                }}
                datatype={datatype.decimal2}
                name={'supplyZeta'}
                keyboardNavigation={props.keyboardNavigation}
              />

              <GridInputTd
                tdClassName={classes.cell}
                value={valveConnectorGrid?.supplyZetaDuplex}
                row={valveConnectorGrid}
                onCommit={(row: any, name: string, value: any) => {
                  updateValveConnectorGridsEdit({ ...valveConnectorGrid, supplyZetaDuplex: value }).then((result) => {
                    props.valveConnectorGridUpdated(result);
                  });
                }}
                datatype={datatype.decimal2}
                name={'supplyZetaDuplex'}
                keyboardNavigation={props.keyboardNavigation}
              />
              <td rowSpan={2}>
                <ConnectorGridImage
                  gridImageID={valveConnectorGrid?.imageID}
                  valveConnectorGridUpdated={props.valveConnectorGridUpdated}
                  valveConnectorGridID={valveConnectorGrid?.valveConnectorGridID || 0}
                />
              </td>
            </tr>
            <tr>
              <td>E</td>
              <GridInputTd
                tdClassName={classes.cell}
                value={valveConnectorGrid?.extractionZeta}
                row={valveConnectorGrid}
                onCommit={(row: any, name: string, value: any) => {
                  updateValveConnectorGridsEdit({ ...valveConnectorGrid, extractionZeta: value }).then((result) => {
                    props.valveConnectorGridUpdated(result);
                  });
                }}
                datatype={datatype.decimal2}
                name={'extractionZeta'}
                keyboardNavigation={props.keyboardNavigation}
              />

              <GridInputTd
                tdClassName={classes.cell}
                value={valveConnectorGrid?.extractionZetaDuplex}
                row={valveConnectorGrid}
                onCommit={(row: any, name: string, value: any) => {
                  updateValveConnectorGridsEdit({ ...valveConnectorGrid, extractionZetaDuplex: value }).then(
                    (result) => {
                      props.valveConnectorGridUpdated(result);
                    }
                  );
                }}
                datatype={datatype.decimal2}
                name={'extractionZetaDuplex'}
                keyboardNavigation={props.keyboardNavigation}
              />
            </tr>
          </tbody>
        </table>
      </Td>
    );
  } else {
    return <Td></Td>;
  }
};

export default ValveGridCell;
