import React from 'react';
import { DialogTitle, IconButton, Typography, Box, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogTitleProps {
  children: React.ReactNode;
  onClose?: () => void;
  icon?: React.ReactNode;
  iconColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

const CustomDialogTitle: React.FC<CustomDialogTitleProps> = ({
  children,
  onClose,
  icon,
  iconColor = 'primary',
  ...other
}) => {
  const theme = useTheme();
  return (
    <DialogTitle
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        position: 'relative',
      }}>
      {icon ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: theme.spacing(1),
            color: theme.palette[iconColor].main, // Gebruik van theme.palette voor kleur
          }}>
          {icon}
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          paddingLeft: icon ? theme.spacing(1) : 0,
          paddingRight: onClose ? theme.spacing(5) : 0, // Extra padding to avoid overlap with close button
        }}>
        <Typography variant="h6" component="span" sx={{ whiteSpace: 'pre-wrap' }}>
          {children}
        </Typography>
      </Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            // marginRight: theme.spacing(-1),
            color: theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
