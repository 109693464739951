import { createEntityAdapter } from '@reduxjs/toolkit';
import { IPlenumData } from 'application/project/api/PlenumData';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';

export const createPlenumDataId = (data: { supply: PlenumDataSupply; index: number }) =>
  [data.supply, data.index].join('_');

export const plenumDataAdapter = createEntityAdapter<IPlenumData,string>({
  selectId: (plenum) => createPlenumDataId(plenum),
});
