import {
  initialLoadingEntityState,
  LoadingEntityState,
  LoadingState,
  LoadingStringEntityState,
} from 'projectRedux/shared';
import { IPlenumConnection, ISection, ISectionBend, IValveConnection } from '../api/Connection';
import { IEppData } from '../api/EppData';
import { IEppDataComponent } from '../api/EppDataComponent';
import { IPlenumData } from '../api/PlenumData';
import { IProject } from '../api/Project';
import { IProjectArticle } from '../api/ProjectArticle';
import { IProjectExtraComponent } from '../api/ProjectExtraComponent';
import { valveConnectionAdapter } from './adapters/valveConnectionAdapter';
import { eppDataAdapter } from './adapters/eppDataAdapter';
import { eppDataComponentAdapter } from './adapters/eppDataComponentAdapter';
import { plenumConnectionAdapter } from './adapters/plenumConnectionAdapter';
import { plenumDataAdapter } from './adapters/plenumDataAdapter';
import {
  ArticleLoadingEntityState,
  initialProjectArticleEntityState,
  projectArticleAdapter,
} from './adapters/projectArticleAdapter';
import { projectExtraComponentAdapter } from './adapters/projectExtraComponentAdapter';
import { sectionAdapter } from './adapters/sectionAdapter';
import { sectionBendAdapter } from './adapters/sectionBendAdapter';

export interface projectState {
  loading: {
    projects: LoadingState;
  };
  activeProject?: IProject;
  connectionKeyCounter: number;
  plenumdatas: LoadingStringEntityState<IPlenumData>;
  valveConnections: LoadingStringEntityState<IValveConnection>;
  plenumConnections: LoadingStringEntityState<IPlenumConnection>;
  sections: LoadingStringEntityState<ISection>;
  sectionbends: LoadingStringEntityState<ISectionBend>;
  eppdatas: LoadingStringEntityState<IEppData>;
  eppdatacomponents: LoadingEntityState<IEppDataComponent>;
  projectArticles: ArticleLoadingEntityState<IProjectArticle>;
  projectExtraComponents: LoadingEntityState<IProjectExtraComponent>;
}

export const initialState: projectState = {
  loading: {
    projects: 'idle',
  },
  activeProject: undefined,
  connectionKeyCounter: 0,
  plenumdatas: plenumDataAdapter.getInitialState(initialLoadingEntityState),
  valveConnections: valveConnectionAdapter.getInitialState(initialLoadingEntityState),
  plenumConnections: plenumConnectionAdapter.getInitialState(initialLoadingEntityState),
  sections: sectionAdapter.getInitialState(initialLoadingEntityState),
  sectionbends: sectionBendAdapter.getInitialState(initialLoadingEntityState),
  eppdatas: eppDataAdapter.getInitialState(initialLoadingEntityState),
  eppdatacomponents: eppDataComponentAdapter.getInitialState(initialLoadingEntityState),
  projectArticles: projectArticleAdapter.getInitialState(initialProjectArticleEntityState),
  projectExtraComponents: projectExtraComponentAdapter.getInitialState(initialLoadingEntityState),
};
