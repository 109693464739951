import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBends } from 'application/components/api/Bend';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncBends = createAsyncThunk(
  'components/fetchAsyncBends',
  async (data: {}, thunkAPI) => {
    const response = await getBends();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).bends.loading === 'init';
    },
  }
);
