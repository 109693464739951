import { ComponentEntityState, initialComponentEntityState } from 'projectRedux/shared';
import { IBend } from '../api/Bend';
import { IDuct } from '../api/Duct';
import { IDuctAdapter } from '../api/DuctAdapter';
import { IEppColor } from '../api/EppColor';
import { IEppComponent } from '../api/EppComponent';
import { IEppDiameter } from '../api/EppDiameter';
import { IEppMaterial } from '../api/EppMaterial';
import { IEppTerminal } from '../api/EppTerminal';
import { IEppTerminalType } from '../api/EppTerminalType';
import { IEppType } from '../api/EppType';
import { IExtraComponent } from '../api/ExtraComponent';
import { IPlenum } from '../api/Plenum';
import { IValveConnectorGrid } from '../api/ValveConnectorGrid';
import { bendAdapter } from './adapters/bendAdapter';
import { ductAdapter } from './adapters/ductAdapter';
import { ductAdapterAdapter } from './adapters/ductAdapterAdapter';
import { eppColorAdapter } from './adapters/eppColorAdapter';
import { eppDiameterAdapter } from './adapters/eppDiameterAdapter';
import { eppMaterialAdapter } from './adapters/eppMaterialAdapter';
import { eppTerminalAdapter } from './adapters/eppTerminalAdapter';
import { eppTerminalTypeAdapter } from './adapters/eppTerminalTypeAdapter';
import { eppComponentAdapter } from './adapters/eppComponentAdapter';
import { eppTypeAdapter } from './adapters/eppTypeAdapter';
import { extraComponentAdapter } from './adapters/extraComponentAdapter';
import { plenumAdapter } from './adapters/plenumAdapter';
import { valveConnectorGridAdapter } from './adapters/valveConnectorGridAdapter';
import { IEppDamperType } from '../api/EppDamperTerminal';
import { IEppDamper } from '../api/EppDamper';
import { eppDamperAdapter } from './adapters/eppDamperAdapter';
import { eppDamperTypeAdapter } from './adapters/eppDamperTypeAdapter';

export interface componentState {
  bends: ComponentEntityState<IBend>;
  ductadapters: ComponentEntityState<IDuctAdapter>;
  ducts: ComponentEntityState<IDuct>;
  eppColors: ComponentEntityState<IEppColor>;
  eppComponents: ComponentEntityState<IEppComponent>;
  eppDiameters: ComponentEntityState<IEppDiameter>;
  eppMaterials: ComponentEntityState<IEppMaterial>;
  eppTerminalTypes: ComponentEntityState<IEppTerminalType>;
  eppTerminals: ComponentEntityState<IEppTerminal>;
  eppDamperTypes: ComponentEntityState<IEppDamperType>;
  eppDampers: ComponentEntityState<IEppDamper>;
  eppTypes: ComponentEntityState<IEppType>;
  extraComponents: ComponentEntityState<IExtraComponent>;
  plenums: ComponentEntityState<IPlenum>;
  valveConnectorGrids: ComponentEntityState<IValveConnectorGrid>;
}

export const initialState: componentState = {
  bends: bendAdapter.getInitialState(initialComponentEntityState),
  ductadapters: ductAdapterAdapter.getInitialState(initialComponentEntityState),
  ducts: ductAdapter.getInitialState(initialComponentEntityState),
  eppColors: eppColorAdapter.getInitialState(initialComponentEntityState),
  eppComponents: eppComponentAdapter.getInitialState(initialComponentEntityState),
  eppDiameters: eppDiameterAdapter.getInitialState(initialComponentEntityState),
  eppMaterials: eppMaterialAdapter.getInitialState(initialComponentEntityState),
  eppTerminalTypes: eppTerminalTypeAdapter.getInitialState(initialComponentEntityState),
  eppTerminals: eppTerminalAdapter.getInitialState(initialComponentEntityState),
  eppDamperTypes: eppDamperTypeAdapter.getInitialState(initialComponentEntityState),
  eppDampers: eppDamperAdapter.getInitialState(initialComponentEntityState),
  eppTypes: eppTypeAdapter.getInitialState(initialComponentEntityState),
  extraComponents: extraComponentAdapter.getInitialState(initialComponentEntityState),
  plenums: plenumAdapter.getInitialState(initialComponentEntityState),
  valveConnectorGrids: valveConnectorGridAdapter.getInitialState(initialComponentEntityState),
};
