import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const editorStyles = makeStyles((theme: Theme) => ({
  image: {
    height: 100,
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },

  imageContainer: {
    padding: theme.spacing(1),
  },

  TextBlockHost: {
    //paddingBottom: theme.spacing(1),
  },
}));
