import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getProject } from 'application/project/api/Project';
import { asyncSetActiveProject } from 'application/project/redux/thunks/AsyncProject';
import { pages_project_list } from '../Edit/EditorGrid/useRoutes';

interface ProjectParameter {
  guid: string | undefined;
}

const SetActiveProject = () => {
  const dispatch = useDispatch();
  const urlParameter: ProjectParameter = useParams<ProjectParameter>();
  const history = useHistory();
  const location = useLocation();
  const { guid } = urlParameter;

  React.useEffect(() => {
    //initieel het project laden
    if (guid) {
      getProject(guid)
        .then((result) => {
          dispatch(asyncSetActiveProject(result) as any);
          const searchParams = new URLSearchParams(location.search);
          const url = searchParams.get('url');
          if (url && url.length > 0) {
            history.replace(url);
          } else {
            history.replace('/project/edit');
          }
        })
        .catch((ex) => {
          console.error(ex);
          history.replace(pages_project_list);
        });
    }
  }, [history, guid, dispatch, location.search]);

  return <div>set active project ...</div>;
};
export default SetActiveProject;
