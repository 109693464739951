import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Provider, useDispatch } from 'react-redux';
import { store } from './projectRedux/store';

import './custom.css';
import SetActiveProject from './application/project/view/setters/SetActiveProject';
import Editor from './application/project/view/Edit/Editor';
import axios from 'axios';
import authService from './components/api-authorization/AuthorizeService';
import { ThemeProvider } from '@mui/material/styles';
import { globalTheme } from 'application/shared/styles';
import { HomeWrapper } from 'application/HomeWrapper';
import { commonSlice } from 'application/common/redux/commonSlice';
import { TestenAuthService } from 'components/api-authorization/bearerToken';
import { useTranslation } from 'react-i18next';
import { getLanguage, setBackendLanguage, supportedLanguages } from './projecti18next/languages';
import { setLanguage } from 'application/common/redux/thunks/setLanguage';
import EditComponentRoutes from 'application/editcomp/views/EditComponentRoutes';
import { pages_project_list } from 'application/project/view/Edit/EditorGrid/useRoutes';
import ProjectList from 'application/project/view/List/ProjectList';

// Add a 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (401 === error.response.status) {
      const isauthenticated = await authService.isAuthenticated();
      if (isauthenticated) {
        authService.updateState(null);
        await authService.signOut({});
        /*
        dit werkte niet zo lekker
        */
      }
    } else {
      return Promise.reject(error);
    }
  }
);

const AppWithStoreAndTheme = () => {
  const dispatch = useDispatch();
  const [roles, setRoles] = React.useState<string[]>([]);
  const { i18n } = useTranslation();

  const changeLanguage = React.useCallback(
    async (language: string, setServer: boolean = true) => {
      if (i18n.language !== language) {
        document.documentElement.lang = language;
        await dispatch(commonSlice.actions.LanguageChanged(language));
        if (setServer) {
          await setBackendLanguage(language);
        }
        i18n.changeLanguage(language);
        dispatch(setLanguage(language) as any);
      }
    },
    [i18n, dispatch]
  );

  React.useEffect(() => {
    authService.getRoles().then((resRoles) => {
      if (componentIsMounted.current) setRoles(resRoles);
    });
  }, []);
  const componentIsMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  authService.getAccessToken().then((token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  });

  React.useEffect(() => {
    TestenAuthService();
    changeLanguage(getLanguage(), false);

    authService.getRawUser().then((user) => {
      if (!user) {
        dispatch(commonSlice.actions.userChanged(null));
      } else {
        dispatch(
          commonSlice.actions.userChanged({
            contactName: user.profile.contactName,
            userEmail: user.profile.email,
            userID: user.profile.sub,
            language: user.profile.language,
            roles: user.profile.role,
            expires_at: new Date(user.expires_at * 1000).toISOString(),
            expires_in: user.expires_in,
          })
        );
        if (supportedLanguages.includes(user.profile.language)) {
          changeLanguage(user.profile.language);
        }
      }
    });
  }, [dispatch, changeLanguage, i18n]);

  return (
    <Layout currentLanguage={i18n.language} onChangeLanguage={changeLanguage} languages={supportedLanguages}>
      <AuthorizeRoute path="/project/setactive/:guid" component={SetActiveProject} />
      <Route exact path="/" component={HomeWrapper} />
      <AuthorizeRoute exact path={pages_project_list} component={ProjectList} />
      <AuthorizeRoute path="/project/edit" component={Editor} />
      {roles.includes('EditComponent') ? (
        <AuthorizeRoute path="/EditComponent" component={EditComponentRoutes} />
      ) : null}
      <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
    </Layout>
  );
};

/*
const Spinner = (props: any) => {
  const { delay = 0 } = props;
  const [ready, setReady] = React.useState(delay === 0);
  React.useEffect(() => {
    let timeout: any = null;
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [ready, delay]);
  if (!ready) {
    console.log('spinner null');
    return null;
  }
  console.log('spinner loading');
  return (
    <div>loading ...</div>
    // Spinner
  );
};
*/

//https://dev.to/lakhansamani/how-to-use-react-suspense-and-lazy-for-code-splitting-3fo2

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={globalTheme}>
        <Provider store={store}>
          <AppWithStoreAndTheme />
        </Provider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
