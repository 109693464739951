import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CustomDialogTitle from './CustomDialogTitle';

export enum BaseButtonType {
  close,
  submit,
  custom,
}

export interface IBaseButtonProps {
  OnClick?: any;
  name?: string;
  label: string;
  disabled?: any;
  color?: string;
  type: BaseButtonType;
}

export interface IBaseDialogProps {
  IsOpen: boolean;
  onClose?: any;
  onApply?: any;
  title: string;
  children?: any;
  buttons: IBaseButtonProps[];
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export interface IBaseDialogState {
  IsOpen: boolean;
}

export interface ButtonPropertiesType {
  onClick: any;
  disabled: boolean;
  color: any;
}

export default class BaseDialog extends React.Component<IBaseDialogProps, IBaseDialogState> {
  constructor(props: any) {
    super(props);
    this.state = {
      IsOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IBaseDialogProps, prevState: IBaseDialogState) {
    if (nextProps.IsOpen !== prevState.IsOpen) {
      return { IsOpen: nextProps.IsOpen };
    }
    return {};
  }

  handleClose = () => {
    this.setState({ IsOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  handleApply = (e: any) => {
    e.preventDefault();
    this.setState({ IsOpen: false });
    if (this.props.onApply) {
      this.props.onApply();
    }
  };

  getButtonProperties = (but: IBaseButtonProps): ButtonPropertiesType => {
    let retval: ButtonPropertiesType = {
      onClick: but.type === BaseButtonType.close && this.props.onClose ? this.props.onClose : but.OnClick,
      disabled: typeof but.disabled === 'function' ? but.disabled() : but.disabled,
      color: 'primary',
    };
    switch (but.type) {
      case BaseButtonType.close:
        retval.color = 'secondary';
        retval.onClick = this.handleClose;
        break;
      case BaseButtonType.submit:
        break;
    }
    return retval;
  };

  render() {
    return (
      <div>
        <Dialog
          disableScrollLock={true}
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          fullWidth={this.props.fullWidth}
          maxWidth={this.props.maxWidth}
          fullScreen={this.props.fullScreen}
          open={this.state.IsOpen}>
          <form onSubmit={this.handleApply}>
            <CustomDialogTitle onClose={this.handleClose}>{this.props.title}</CustomDialogTitle>
            <DialogContent dividers>{this.props.children}</DialogContent>
            <DialogActions>
              {this.props.buttons.map((but, index) => {
                let butProp = this.getButtonProperties(but);
                return (
                  <Button
                    key={index}
                    onClick={butProp.onClick}
                    disabled={butProp.disabled}
                    color={butProp.color}
                    type={but.type === BaseButtonType.submit ? 'submit' : undefined}>
                    {but.label}
                  </Button>
                );
              })}
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
