import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppComponents } from 'application/components/api/EppComponent';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncEppComponents = createAsyncThunk(
  'components/fetchAsyncEppComponents',
  async (data: {}, thunkAPI) => {
    const response = await getEppComponents();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppComponents.loading === 'init';
    },
  }
);
