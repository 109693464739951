import { createAsyncThunk } from '@reduxjs/toolkit';
import { getValveGridsEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveGridEdit';
import { selectCompEditState } from 'projectRedux/store';

export const fetchAsyncValveGridsEdit = createAsyncThunk(
  'editcomp/fetchAsyncValveGridsEdit',
  async (data: {}, thunkAPI) => {
    const response = await getValveGridsEdit();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).valveGrids.loading === 'init';
    },
  }
);
