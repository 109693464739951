import * as React from 'react';
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';

interface IOptimisticCheckBoxProps {
  active?: boolean;
  onChange: (active: boolean | undefined) => void;
  title?: string;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  isTriState?: boolean;
  preventChange?: boolean;
  parentRef?: (node: any) => any;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => any;
  color?: 'default' | 'primary' | 'secondary' | undefined;
}
const OptimisticCheckBox = (props: IOptimisticCheckBoxProps) => {
  const [Active, setActive] = React.useState(props.active);

  React.useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  function onChange(event: any, checked: boolean) {
    event.stopPropagation();
    let active: boolean | undefined;
    if (!!!props.onChange) return;
    if (props.isTriState) {
      if (Active === true) active = false;
      else if (Active === false) active = undefined;
      else active = true;
    } else {
      active = checked;
    }
    //optimistic change
    if (props.preventChange !== true) setActive(active);
    //tell our parent ..
    props.onChange(active);
  }

  return (
    <Checkbox
      title={props.title}
      style={props.style}
      disabled={props.disabled}
      color={props.color}
      indeterminate={Active === undefined || Active === null}
      className={clsx(props.className)}
      checked={Active !== undefined ? Active : false}
      readOnly={!!!props.onChange}
      onChange={onChange}
      onClick={(evt: any) => {
        evt.stopPropagation();
      }}
    />
  );
};

export default OptimisticCheckBox;
