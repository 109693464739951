import { IProject } from 'application/project/api/Project';
import TextInputField from 'components/controls/TextInputField';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { nameof } from 'application/shared/nameof';
import { IUseEditProjectProps } from './useEditProject';

interface MyIUseEditProjectProps extends IUseEditProjectProps {
  project: IProject;
}

const EditProject = (props: MyIUseEditProjectProps) => {
  const { t } = useTranslation(['project']);

  return (
    <div>
      <div>
        <TextInputField
          label={t('title', 'title')}
          name={nameof<IProject>('title')}
          required
          value={props.project?.title}
          onChange={(value: string, name: string, error: string) => {
            props.changeField(value, name, error);
          }}
          fullWidth
          helperText=" "
        />
      </div>
      <div>
        <TextInputField
          label={t('address', 'address')}
          name={nameof<IProject>('address')}
          value={props.project?.address}
          onChange={(value: string, name: string, error: string) => {
            props.changeField(value, name, error);
          }}
          fullWidth
          helperText=" "
        />
      </div>
      <div>
        <TextInputField
          label={t('description', 'description')}
          name={nameof<IProject>('description')}
          value={props.project?.description}
          onChange={(value: string, name: string, error: string) => {
            props.changeField(value, name, error);
          }}
          fullWidth
          helperText=" "
        />
      </div>
    </div>
  );
};
export default EditProject;
