import React from 'react';
import packshot from 'image/packshot.jpg';
import headervision from 'image/headervision.jpg';
import ActionButton from 'components/controls/ActionButton';
import { useTranslation } from 'react-i18next';
import { HomeUL } from './controls/HomeUL';
import preval from 'preval.macro';
import { format } from 'date-fns';
import { locales } from 'projecti18next/i18n';

const publishStyle: React.CSSProperties = { fontStyle: 'italic' };

const pubishDate = new Date(preval`module.exports = new Date().toISOString();`);

interface IRenderText {
  type: 'p' | 'li' | 'ul';
  content: string | IRenderText[];
}
const Home = () => {
  const { t, i18n } = useTranslation(['home']);
  const [parts, setParts] = React.useState<IRenderText[]>([]);
  React.useEffect(() => {
    //deze tekst moeten we splitsen in een aantal gedeeltes.
    //1 stuk voor tekst voor bullets
    //2 de bullets
    //3 de tekst erna.
    const parts = t('bodyHtml', 'stukje tekst').split('\n');
    const newParts: IRenderText[] = [];
    let parent: IRenderText | undefined = undefined;
    parts.forEach((part) => {
      if (part.trim().startsWith('•')) {
        if (parent === undefined) {
          parent = {
            type: 'ul',
            content: [],
          };
          newParts.push(parent);
        }
        (parent.content as IRenderText[]).push({ type: 'li', content: part.trim().substr(1).trim() });
      } else {
        parent = undefined;
        newParts.push({ type: 'p', content: part.trim() });
      }
    });
    setParts(newParts);
  }, [t, i18n.language]);
  const locale: any | undefined = (locales as any)[i18n.language];

  return (
    <div>
      <div
        style={{
          height: 350,
          position: 'absolute',
          backgroundColor: '#0066a4',
          width: '100vw',
          maxWidth: '100%',
          left: 0,
          textAlign: 'center',
          top: 114,
          /*
                    borderBottomColor: '#ee7203',
                    borderBottomWidth: 4,
                    borderBottomStyle: 'solid',
                    */
          borderTopColor: '#ee7203',
          borderTopWidth: 4,
          borderTopStyle: 'solid',
        }}>
        <img
          style={{
            maxHeight: '100%',
          }}
          src={headervision}
          alt="Hybalans header vision"
        />
        <div className="container">
          <p
            className="titlefont"
            style={{ bottom: 10, position: 'absolute' }}
            dangerouslySetInnerHTML={{
              __html: t('HybalansTitle', 'HYBALANS+ AIR DISTRIBUTION').replace(
                '+',
                "<span style='vertical-align: super;'>+</span>"
              ),
            }}
          />
        </div>
      </div>

      <div className="content-entry" style={{ marginTop: 350 }}>
        <div className="row">
          <div className="col-md-6">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                {parts.map((part, index) => {
                  if (part.type === 'p') {
                    return <p key={index}>{part.content as string}</p>;
                  } else if (part.type === 'ul') {
                    return (
                      <HomeUL key={index}>
                        {(part.content as IRenderText[]).map((subpart, index2) => {
                          return <li key={'li' + index2}>{subpart.content as string}</li>;
                        })}
                      </HomeUL>
                    );
                  } else return null;
                })}
                <p style={publishStyle}>
                  {t('publishdate', 'published')}: {format(pubishDate, 'P', { locale })}{' '}
                  {format(pubishDate, 'p', { locale })}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                maxWidth: '100%',
                maxHeight: 375,
              }}
              src={packshot}
              alt="Burgerhout HybalansSystem"
            />
          </div>
        </div>
        <div>
          <ActionButton
            className="inlinebutton"
            helperlink
            caption={t('registerlink', 'register')}
            href="/authentication/register"
            title={t('registerlinktitle', 'register')}
          />

          <ActionButton
            className="inlinebutton"
            caption={t('loginlink', 'login')}
            href="/authentication/login"
            title={t('loginlinktitle', 'log in')}
          />
        </div>

        {/*
        <p>Welcome to your new single-page application, built with:</p>
        <ul>
          <li>
            <a href="https://get.asp.net/">ASP.NET Core</a> and{' '}
            <a href="https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx">C#</a> for cross-platform server-side code
          </li>
          <li>
            <a href="https://facebook.github.io/react/">React</a> for client-side code
          </li>
          <li>
            <a href="http://getbootstrap.com/">Bootstrap</a> for layout and styling
          </li>
        </ul>
        <p>To help you get started, we have also set up:</p>
        <ul>
          <li>
            <strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return
            here.
          </li>
          <li>
            <strong>Development server integration</strong>. In development mode, the development server from{' '}
            <code>create-react-app</code> runs in the background automatically, so your client-side resources are
            dynamically built on demand and the page refreshes when you modify any file.
          </li>
          <li>
            <strong>Efficient production builds</strong>. In production mode, development-time features are disabled,
            and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.
          </li>
        </ul>
        <p>
          The <code>ClientApp</code> subdirectory is a standard React application based on the{' '}
          <code>create-react-app</code> template. If you open a command prompt in that directory, you can run{' '}
          <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.
        </p>
         */}
      </div>
    </div>
  );
};

export default Home;
