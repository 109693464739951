import { getValveConnectorGridImage } from 'application/components/api/ValveConnectorGrid';
import {
  selectAllValveConnectorGrids,
  selectValveConnectorGridById,
} from 'application/components/redux/selectors/valveConnectorGridSelector';
import { IValveConnection, SECTIONS_AS_PLENUMCONNECTION_0 } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import { makeSelectSectionsByConnection } from 'application/project/redux/selectors/sectionSelectors';
import { PlenumDataSupply, enValveConnectorConnection } from 'application/shared/PlenumDataSupply';
import clsx from 'clsx';
import NumberInputField from 'components/controls/NumberInputField';
import Selector from 'components/controls/Selector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckBoxSelect from './controls/CheckBoxSelect';
import connectionStyles from './controls/connectionStyles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const valveConnectorIDToRightAngled = (valveConnectorID: number | undefined) =>
  valveConnectorID === undefined || valveConnectorID === enValveConnectorConnection.rightAngled;
const rightAngledToValveConnectorID = (rightAngled: boolean | undefined) =>
  rightAngled === false ? enValveConnectorConnection.straight : enValveConnectorConnection.rightAngled;

const PartValve = (props: {
  plenumdata: IPlenumData;
  connection: IValveConnection;
  onChange: (value: number | boolean | undefined, field: string, error: string) => any;
}) => {
  const classes = connectionStyles();
  const { onChange, plenumdata, connection } = props;
  const { valveConnectorGridID } = connection;

  const project = useSelector(selectActiveProject);
  const allValveConnectorGrids = useSelector(selectAllValveConnectorGrids);
  const valveConnectorGrid = useSelector((state: any) =>
    selectValveConnectorGridById(state, valveConnectorGridID || 0)
  );
  const [rightAngled, setRightAngled] = React.useState<boolean>(
    valveConnectorIDToRightAngled(valveConnectorGrid?.valveConnectionID)
  );
  const [valveGridID, setValveGridID] = React.useState<number | undefined>(valveConnectorGrid?.valveGridID);
  const selectSectionsByConnection = React.useMemo(makeSelectSectionsByConnection, []);
  const sections = useSelector((state) =>
    selectSectionsByConnection(state, props.connection.valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0)
  );
  const ductID = sections[sections.length - 1]?.ductID ?? 0;

  const valveConnectorGrids = React.useMemo(() => {
    const res = allValveConnectorGrids.filter(
      (a) =>
        a.ductID === ductID &&
        (a.plenumConnector || a.valveDiameterID === project?.valveDiameterID) &&
        (a.plenumConnector || a.valveConnectionID === rightAngledToValveConnectorID(rightAngled)) &&
        ((plenumdata.supply === PlenumDataSupply.supply && a.canSupply) ||
          (plenumdata.supply === PlenumDataSupply.extraction && a.canExtraction))
    );
    res.sort((a, b) => {
      let valCompResult = (a.isCustom ? 1 : 0) - (b.isCustom ? 1 : 0);
      if (valCompResult === 0) valCompResult = (a.plenumConnector ? 1 : 0) - (b.plenumConnector ? 1 : 0);
      if (valCompResult === 0) valCompResult = a.name.localeCompare(b.name);
      if (valCompResult === 0) valCompResult = (a.canDuplex ? 1 : 0) - (b.canDuplex ? 1 : 0);
      return valCompResult;
    });
    return res;
  }, [project, rightAngled, plenumdata.supply, ductID, allValveConnectorGrids]);

  const findRelevantValveConnectorGrid = React.useCallback(
    (rightAngled: boolean, valveGridID: number | undefined, ductID: number | undefined) =>
      allValveConnectorGrids.find(
        (a) =>
          a.valveDiameterID === (project === undefined ? 0 : project.valveDiameterID) &&
          a.valveConnectionID === rightAngledToValveConnectorID(rightAngled) &&
          a.valveGridID === valveGridID &&
          a.ductID === ductID &&
          ((plenumdata.supply === PlenumDataSupply.supply && a.canSupply === true) ||
            (plenumdata.supply === PlenumDataSupply.extraction && a.canExtraction === true))
      ),
    [allValveConnectorGrids, plenumdata.supply, project]
  );

  const rightAngledChanged = (rightAngledValue: boolean) => {
    setRightAngled(rightAngledValue);
    //recht - haaks is gewisseld, we willen het valveConnectorGrid selecteren dat hetzelfde we hadden
    const newValveConnectorGrid = findRelevantValveConnectorGrid(rightAngledValue, valveGridID, ductID);
    if (newValveConnectorGrid?.valveConnectorGridID !== valveConnectorGridID) {
      onChange(newValveConnectorGrid?.valveConnectorGridID, 'valveConnectorGridID', '');
    }
  };

  //find item voor rightAngled, valveGridID, ductID
  //deze is nodig om wissel tussen duct goed te laten verlopen en geen vervelende waarschuwingen te tonen
  const newValue = React.useMemo(() => {
    const newValue = findRelevantValveConnectorGrid(rightAngled, valveGridID, ductID)?.valveConnectorGridID;
    const valueValid = valveConnectorGrids.find((a) => a.valveConnectorGridID === valveConnectorGridID) !== undefined;
    return valueValid ? valveConnectorGridID : newValue;
  }, [findRelevantValveConnectorGrid, rightAngled, valveGridID, ductID, valveConnectorGrids, valveConnectorGridID]);

  const valveConnectorGridChanged = React.useCallback(
    (value: number | boolean | undefined, field: string, error: string) => {
      const newValveConnectorGrid = allValveConnectorGrids.find((a) => a.valveConnectorGridID === value);

      setValveGridID(newValveConnectorGrid?.valveGridID);
      if (newValveConnectorGrid?.valveConnectionID) {
        setRightAngled(valveConnectorIDToRightAngled(newValveConnectorGrid?.valveConnectionID));
      }
      onChange(value, field, error);
    },
    [onChange, allValveConnectorGrids, setValveGridID, setRightAngled]
  );

  const { t } = useTranslation(['roomeditor']);
  const { valveImage } = classes;

  const selectorItems = React.useMemo(
    () =>
      valveConnectorGrids.map((valve) => (
        <MenuItem key={valve.valveConnectorGridID} value={valve.valveConnectorGridID.toString()}>
          <div>
            {valve.imageID ? (
              <img
                src={getValveConnectorGridImage(valve.valveConnectorGridID, valve.imageID)}
                className={valveImage}
                alt={valve.name}
              />
            ) : null}
          </div>
          <div>{valve.name}</div>
        </MenuItem>
      )),
    [valveConnectorGrids, valveImage]
  );

  const ductIDref = React.useRef(ductID);
  React.useEffect(() => {
    if (ductIDref.current !== ductID) {
      //hebben we een ander kanaal, dan een andere valveConnectorGrid zoeken voor hetzelfde rightAngled, valveGridID
      ductIDref.current = ductID;
      if (newValue !== valveConnectorGridID) {
        onChange(newValue, 'valveConnectorGridID', '');
      }
    }
  }, [onChange, newValue, ductID, valveConnectorGridID]);

  return (
    <div className={clsx(classes.partBlock)}>
      {valveConnectorGrid?.onlyWall && plenumdata.supply === PlenumDataSupply.supply ? (
        <Typography className="valvewarn">{t('warnOnlyWall', 'note: only through the wall')}</Typography>
      ) : null}
      <div className={clsx(classes.halfLeft)}>
        <Selector
          className={classes.flexGrowHor}
          required
          label={!valveConnectorGridID ? t('valveLabel', 'valve') : undefined}
          value={newValue}
          name={'valveConnectorGridID'}
          onChange={valveConnectorGridChanged}
          items={selectorItems}
          fullWidth
        />
      </div>
      <div className={clsx(classes.halfRight)}>
        {plenumdata.supply === PlenumDataSupply.supply ? (
          <div>
            <CheckBoxSelect
              value={connection.hasFilter}
              name={'hasFilter'}
              onChange={onChange}
              label={t('valvefilter', 'filter')}
            />
          </div>
        ) : null}
        <div>
          <CheckBoxSelect
            value={rightAngled === true}
            name={'rightAngled'}
            onChange={(valve: boolean | number) => rightAngledChanged(valve === true)}
            label={t('valveElbow', 'elbow')}
          />
        </div>
        {valveConnectorGrid?.canDuplex ? (
          <div>
            <CheckBoxSelect
              value={connection.dual}
              name={'dual'}
              onChange={onChange}
              label={t('valveDuplex', 'duplex')}
            />
          </div>
        ) : null}

        {valveConnectorGrid?.isCustom ? (
          <div>
            <NumberInputField
              value={connection.customValveResistance}
              name={'customValveResistance'}
              onChange={onChange}
              label={t('valveCustomRestance', 'resistance')}
              required
              type="decimal"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PartValve;
