import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppTerminals } from 'application/components/api/EppTerminal';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncEppTerminals = createAsyncThunk(
  'components/fetchAsyncEppTerminal',
  async (data: {}, thunkAPI) => {
    const response = await getEppTerminals();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppTerminals.loading === 'init';
    },
  }
);
