import { IEppComponent } from 'application/components/api/EppComponent';
import { selectEppTypeById } from 'application/components/redux/selectors/eppTypeSelector';
import { IEppData } from 'application/project/api/EppData';
import { IEppDataComponent } from 'application/project/api/EppDataComponent';
import { updateEppDataComponentThunk } from 'application/project/redux/thunks/AsyncEppDataComponent';
import AmountInputField from 'components/controls/AmountInputField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const newEppDataComponent = (eppData: IEppData, eppComponent: IEppComponent): IEppDataComponent => {
  return {
    eppDataComponentID: 0,
    supply: eppData.supply,
    inside: eppData.inside,
    eppComponentID: eppComponent.eppComponentID,
    eppTypeID: eppComponent.eppTypeID,
    amount: undefined,
    plenumIndex: eppData.plenumIndex,
  };
};

export const EppComponent = (props: {
  component: IEppComponent;
  eppDataComponent: IEppDataComponent | undefined;
  eppData: IEppData;
}) => {
  const dispatch = useDispatch();
  const EppType = useSelector((state) => selectEppTypeById(state, props.component.eppTypeID));

  const onChange = React.useCallback(
    (value: number | undefined, name: string, error: string) => {
      const newValue = props.eppDataComponent
        ? { ...props.eppDataComponent }
        : newEppDataComponent(props.eppData, props.component);
      newValue.amount = value;
      dispatch(updateEppDataComponentThunk(props.eppData, newValue) as any);
    },
    [dispatch, props.component, props.eppDataComponent, props.eppData]
  );

  return (
    <AmountInputField
      label={EppType?.name}
      value={props.eppDataComponent?.amount}
      name={props.component.eppComponentID.toString()}
      onChange={onChange}
    />
  );
};

export default EppComponent;
