import { createSelector } from '@reduxjs/toolkit';
import { selectComponentState } from 'projectRedux/store';
import { bendAdapter } from '../adapters/bendAdapter';

export enum enBendType {
  bend90 = 1,
  bend90supportfoot,
  bendHorizontal,
  bendVertical,
}

export const {
  selectAll: selectAllBends,
  selectById: selectBendById,
  selectEntities: selectBendEntities,
} = bendAdapter.getSelectors((state) => selectComponentState(state).bends);

export const makeSelectBends = () => {
  return createSelector(
    // First, pass one or more "input selector" functions:
    (state: any, ductTypeID: number | undefined) => ductTypeID,
    (state: any) => selectAllBends(state),
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (ductTypeID, bends) => bends.filter((a) => a.ductID === ductTypeID)
  );
};
