import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';

const Selector = (props: {
  value: string | number | undefined;
  name: string;
  label?: string;
  helperText?: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
  onBlur?: (value: string) => string;
  required?: boolean;
  displayEmpty?: boolean;
  style?: any;
  readOnly?: boolean;
  fullWidth?: boolean;
  className?: string;
  items: any[];
}) => {
  const { onChange, onBlur } = props;

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const val = event.target.value as string;
      onChange(val ? parseInt(val) : undefined, props.name, '');
    },
    [onChange, props.name]
  );
  return (
    <FormControl
      variant="filled"
      className={props.className}
      fullWidth={props.fullWidth}
      required={props.required}
      style={props.style}
      error={React.useMemo(
        () => props.required && (props.value === undefined || props.value === 0),
        [props.value, props.required]
      )}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        displayEmpty={props.displayEmpty}
        value={props.value ? props.value.toString() : ''}
        onChange={handleChange}
        onBlur={React.useCallback(
          (event: React.FocusEvent<HTMLInputElement>) => {
            if (onBlur) {
              onBlur(event.target.value);
            }
          },
          [onBlur]
        )}>
        {props.items}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default Selector;
