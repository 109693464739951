import * as React from 'react';
import { IEditorTabs } from './IEditorTabs';
import { Route, Switch } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const EditorSwitch = (props: {
  theEditorTabs: IEditorTabs[];
  image: string | undefined;
  onPrev: () => any;
  onNext: () => any;
  canPrev: boolean;
  canNext: boolean;
}) => {
  const theme = useTheme();
  return (
    <div>
      {props.image ? (
        <img
          style={{ right: theme.spacing(2), position: 'absolute', top: 150, width: 300, zIndex: -1 }}
          alt="map"
          src={props.image}
        />
      ) : null}
      <Switch>
        {props.theEditorTabs.map((tab) => (
          <Route path={tab.path} exact={tab.exact} key={tab.path}>
            {tab.component}
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default EditorSwitch;
