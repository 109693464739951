import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlenums } from 'application/components/api/Plenum';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncPlenums = createAsyncThunk(
  'components/fetchAsyncPlenums',
  async (data: {}, thunkAPI) => {
    const response = await getPlenums();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).plenums.loading === 'init';
    },
  }
);
