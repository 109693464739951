import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormatDecimal, FormatInteger, FormatMin2HourMin, ParseDecimal } from 'application/shared/format';
import clsx from 'clsx';
import * as React from 'react';

export const gridInputStyles = makeStyles((theme: Theme) => ({
  margin: {
    marginLeft: theme.spacing(6),
  },
  cellright: {
    textAlign: 'right',
  },
  cellleft: {
    textAlign: 'left',
  },
  gridtd: {
    padding: 0,
    position: 'relative',
  },
  editmode: {
    background: '#FFCCD5',
  },
  inputcell: {
    paddingLeft: 3,
    paddingRight: 3,
    margin: 0,
    border: 0,
    width: '100%',
  },
  inputTdChild: {
    position: 'absolute',
    top: 0,
    height: '100%',
  },
  error: {
    fontWeight: 'bold',
    color: '#FF0000',
  },
}));

export enum datatype {
  integer = 1,
  decimal1,
  decimal2,
  decimal3,
  decimal4,
  string,
  minInHourMin,
}

export interface IGridInputProps {
  value: any;
  row: any;
  onCommit?: (row: any, name: string, value: any) => any;
  onKeyboardNavigation?: (name: string, event: any) => any;
  refCallBack?: (name: string, node: any) => void;
  focusElement?: () => any;
  datatype: datatype;
  className?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  unit?: string;
  tabIndex?: number;
}

export const GridInput = (props: IGridInputProps) => {
  const textInput = React.useRef();
  const [value, setValue] = React.useState('');
  const orgRef = React.useRef('');
  const classes = gridInputStyles();

  const formatValue = React.useCallback(
    (val: any, format: boolean) => {
      switch (props.datatype) {
        case datatype.integer:
        case datatype.decimal1:
        case datatype.decimal2:
        case datatype.decimal3:
        case datatype.decimal4: {
          let szVal = '';
          if (!isNaN(val)) {
            if (format === true) {
              switch (props.datatype) {
                case datatype.integer:
                  szVal = FormatInteger(val);
                  break;
                case datatype.decimal1:
                  szVal = FormatDecimal(val, 1);
                  break;
                case datatype.decimal2:
                  szVal = FormatDecimal(val, 2);
                  break;
                case datatype.decimal3:
                  szVal = FormatDecimal(val, 3);
                  break;
                case datatype.decimal4:
                  szVal = FormatDecimal(val, 4);
                  break;
                default:
                  szVal = val.toString();
                  break;
              }
            } else {
              szVal = val !== undefined && val != null ? val.toString() : '';
            }
          }
          return szVal + (format === true && props.unit ? props.unit : '');
        }
        case datatype.minInHourMin: {
          let szVal = '';
          if (!isNaN(val)) {
            szVal = FormatMin2HourMin(val);
            return szVal;
          }
          return val || '';
        }

        default:
          return val || '';
      }
    },
    [props.datatype, props.unit]
  );

  const valueToValue = React.useCallback(
    (val: any, format: boolean) => {
      orgRef.current = formatValue(val, format);
      setValue(orgRef.current);
    },
    [setValue, formatValue]
  );

  const initStateValue = React.useCallback(
    (format: boolean) => {
      valueToValue(props.value, format);
    },
    [props.value, valueToValue]
  );

  React.useEffect(() => {
    initStateValue(true);
  }, [initStateValue]);

  const getClassName = React.useMemo(() => {
    return clsx(
      classes.inputcell,
      props.className,
      props.disabled ? null : classes.editmode,
      props.disabled ? null : classes.editmode,
      props.datatype === datatype.string ? classes.cellleft : classes.cellright,
      props.error ? classes.error : null
    );
  }, [props.disabled, props.error, classes, props.className, props.datatype]);

  const onChange = (evt: any) => {
    setValue(evt.target.value);
  };

  const onKeyDown = (evt: any) => {
    if (evt.key === 'Escape') {
      // ESCAPE key pressed
      initStateValue(false);
    } else if (evt.key === 'Enter') {
      // Enter key pressed
      acceptChanges();
    }
    //navination

    if (props.onKeyboardNavigation) {
      props.onKeyboardNavigation(props.name, evt);
    }
  };

  const acceptChanges = (): boolean => {
    if (props.onCommit !== undefined) {
      let editvalue: any = value;
      if (orgRef.current !== value) {
        switch (props.datatype) {
          case datatype.integer:
            if (value === '') {
              editvalue = null;
            } else {
              editvalue = parseInt(value);
              if (isNaN(editvalue)) {
                setValue(props.value);
                return true;
              }
            }
            break;
          case datatype.decimal1:
          case datatype.decimal2:
          case datatype.decimal3:
          case datatype.decimal4:
            if (value === '') {
              editvalue = null;
            } else {
              editvalue = ParseDecimal(value);
              if (isNaN(editvalue)) {
                if (value && value.length) {
                  //de waarde was niet te wissen
                  setValue(props.value);
                  return true;
                } else {
                  setValue('');
                  return false;
                }
              }
            }
            break;
          case datatype.string:
            editvalue = editvalue.trim(' ');
            break;
          case datatype.minInHourMin:
            let parts = (editvalue as string).split(/[.,]/);
            if (parts.length >= 2) {
              const val = ParseDecimal(value);
              editvalue = val ? Math.round(val * 60) : '';
            } else {
              parts = (editvalue as string).split(/\W/);
              const hours = parseInt(parts[0]);
              const min = parts.length > 1 ? parseInt(parts[1]) : 0;
              editvalue = hours * 60 + min;
            }
            break;
        }
        if (props.datatype === datatype.string || !isNaN(editvalue) || value === '') {
          props.onCommit(props.row, props.name, editvalue);
          return true;
        }
      }
    }
    return false;
  };

  const getStep = React.useMemo(() => {
    switch (props.datatype) {
      case datatype.integer:
        return 1;
      case datatype.decimal1:
        return 0.1;
      case datatype.decimal2:
        return 0.01;
      case datatype.decimal3:
        return 0.001;
      case datatype.decimal4:
        return 0.0001;
    }
  }, [props.datatype]);

  return (
    <input
      step={getStep}
      className={getClassName}
      tabIndex={props.tabIndex}
      name={props.name}
      value={value || ''}
      onChange={onChange}
      onBlur={() => {
        if (!acceptChanges()) {
          valueToValue(props.value, true);
        }
      }}
      onKeyDown={onKeyDown}
      onFocus={(e) => {
        orgRef.current = formatValue(props.value, false);
        e.target.value = orgRef.current;
        setValue(orgRef.current);
        e.target.select();
      }}
      ref={(node: any) => {
        textInput.current = node;
        if (props.refCallBack) {
          props.refCallBack(props.name, node);
        }
      }}
      disabled={props.disabled}
    />
  );
};
