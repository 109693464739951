import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppDamperTypes } from 'application/components/api/EppDamperTerminal';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncEppDamperTypes = createAsyncThunk(
  'components/fetchAsyncEppDamperTypes',
  async (data: {}, thunkAPI) => {
    const response = await getEppDamperTypes();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).eppDamperTypes.loading === 'init';
    },
  }
);
