import * as React from 'react';
import { IPlenum } from 'application/components/api/Plenum';
import { IPlenumData } from 'application/project/api/PlenumData';
import { getPlenumImage } from 'application/components/api/Image';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useFlowRate } from 'components/controls/useFlowRate';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

//https://stackoverflow.com/questions/35079287/break-elements-onto-new-line-with-flex-grow-and-flex-wrap
export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2),
  },
  button: {
    float: 'right',
  },
  plenumImage: {
    maxHeight: 120,
  },
  plenumImageHost: {
    textAlign: 'right',
  },

  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexGrowHor: {
    flexGrow: 1,
  },
}));

export const PlenumItem = (props: {
  plenum: IPlenum;
  plenumSelect: (plenum: IPlenum) => any;
  plenumdata: IPlenumData;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { FormatAirFlowRateUnit } = useFlowRate();

  return (
    <div className={classes.root} onClick={() => props.plenumSelect(props.plenum)}>
      <Paper className={classes.paper}>
        <div className={classes.flexContainer}>
          <div>
            <Radio value={props.plenum.plenumID.toString()} color="secondary" />
          </div>
          <div className={classes.flexGrowHor}>
            <Typography variant="h6">{props.plenum?.name}</Typography>
            <Typography>
              {t('plenum_maxflowrate', 'Max {{flowrate}}', {
                flowrate: FormatAirFlowRateUnit(props.plenum?.maxFlowRate),
              })}
            </Typography>
            <Typography>
              {t('plenum_numberofconnections', '{{numberConnections}} connections', {
                numberConnections: props.plenum?.numberConnections,
              })}
            </Typography>
          </div>
          <div>
            <img src={getPlenumImage(props.plenum.imageID)} className={classes.plenumImage} alt="" />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default PlenumItem;
