import { EntityState } from '@reduxjs/toolkit';

export type LoadingState = 'init' | 'idle' | 'loading';

export const checkLoading = (state: LoadingState) => {
  return state !== 'idle';
};

export interface initialLoadingEntityStateType {
  loading: LoadingState;
  saving: LoadingState;
}
export const initialLoadingEntityState: initialLoadingEntityStateType = {
  loading: 'init',
  saving: 'idle',
};
export declare interface LoadingEntityState<T> extends EntityState<T, number> {
  loading: LoadingState;
  saving: LoadingState;
}
export declare interface LoadingStringEntityState<T> extends EntityState<T, string> {
  loading: LoadingState;
  saving: LoadingState;
}

export interface initialComponentEntityStateType {
  loading: LoadingState;
}
export const initialComponentEntityState: initialComponentEntityStateType = {
  loading: 'init',
};
export declare interface ComponentEntityState<T> extends EntityState<T, number> {
  loading: LoadingState;
}
