import * as React from 'react';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import { useDispatch, useSelector } from 'react-redux';
import { commonSlice } from 'application/common/redux/commonSlice';
import { useTranslation } from 'react-i18next';
import ActionButton from './ActionButton';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useGlobalStyles } from 'application/shared/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  flexGrowHor: {
    flexGrow: 1,
  },
  extraPadding: {
    paddingRight: theme.spacing(4),
  },
}));

const PageTitle = (props: { title: string; onNext?: () => any; extrapadding?: boolean }) => {
  const dispatch = useDispatch();
  const project = useSelector(selectActiveProject);
  const globalclasses = useGlobalStyles();
  const { t } = useTranslation(['editortabs']);
  const classes = useStyles();
  const theme = useTheme();

  React.useEffect(() => {
    const title = props.title + ' - ' + (project?.title || 'untitled') + ' - Hybalans+';
    document.title = title;
    dispatch(commonSlice.actions.pageTitleChanged(title));
  }, [props.title, project, dispatch]);

  return (
    <div style={{ width: '100%', height: 48 }}>
      <div
        className={clsx(
          classes.flexContainer,
          globalclasses.regularContentWidth,
          props.extrapadding ? classes.extraPadding : null
        )}>
        <div className={classes.flexGrowHor}>
          <Typography variant="h5">{props.title}</Typography>
        </div>
        {props.onNext ? (
          <ActionButton
            style={{ marginBottom: 0, position: 'fixed', right: theme.spacing(2), zIndex: 10 }}
            caption={t('nextbutton', 'next')}
            title={t('nextbuttontitle', 'next')}
            onClick={() => (props.onNext ? props.onNext() : null)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PageTitle;
