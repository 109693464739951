import { IValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { enFlowUnit } from 'application/project/api/Project';
import { selectFlowUnitID } from 'application/project/redux/selectors/projectSelector';
import clsx from 'clsx';
import { useFlowRate } from 'components/controls/useFlowRate';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nameof } from 'application/shared/nameof';
import connectionStyles from './controls/connectionStyles';
import DebietInputField from './controls/DebietInputField';

const PartDebiet = (props: {
  plenumdata: IPlenumData;
  connection: IValveConnection;
  minAirflowRate: number;
  maxAirflowRate: number;
  warnAirflowRate: number;
  plenumMaxAirflowRate: number;
  plenumAirflowRate: number;
  onChange: (value: number, field: string, error: string) => any;
}) => {
  const classes = connectionStyles();
  const { airFlowRateUnit } = useFlowRate();
  const flowUnitID = useSelector(selectFlowUnitID);

  const { t } = useTranslation(['roomeditor']);

  const { onChange } = props;
  const onMyChange = React.useCallback(
    (value: number, name: string, error: string) => {
      onChange(value, name, error);
    },
    [onChange]
  );

  return (
    <div className={clsx(classes.partBlock)}>
      <div className={clsx(classes.halfLeft)}>
        <DebietInputField
          debounce={true}
          minAirflowRate={props.minAirflowRate}
          maxAirflowRate={props.maxAirflowRate}
          warnAirflowRate={props.warnAirflowRate}
          plenumMaxAirflowRate={props.plenumMaxAirflowRate}
          plenumAirflowRate={props.plenumAirflowRate}
          fullWidth
          convertM3H={flowUnitID === enFlowUnit.m3_h}
          required={true}
          label={t('airflowrate', 'air flow rate ({{flowunit}})', {
            flowunit: airFlowRateUnit(),
          })}
          name={nameof<IValveConnection>('airflowRate')}
          value={props.connection.airflowRate}
          onChange={onMyChange}
        />
      </div>
    </div>
  );
};

export default PartDebiet;
