import { IValveConnectorEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorEdit';
import React from 'react';
import { IGridStatePlaceHolder } from '../../controls/hooks/useGridStateData';
import Table from '../../controls/table/Table';
import Td from '../../controls/table/Td';
import Th from '../../controls/table/Th';
import { IGridStateData } from '../ValveConnectorGrid';
import { IRenderRowCallBacks, RenderRow } from './RenderRow';
import ValveGridCell from './ValveGridCell';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useKeyboardNavigation } from '../../controls/hooks/useKeyboardNavigation';
import useTableProps from '../../controls/table/properties/useTableProps';
import { IValveConnectorGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';
import { IValveGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveGridEdit';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ValveConnectorGridTable = (props: {
  state: IGridStatePlaceHolder<IGridStateData>;
  valveConnectors: IValveConnectorEdit[];
  valveConnectorGrids: IValveConnectorGridEdit[];
  valveGrids: IValveGridEdit[];
  valveConnectorGridUpdated: (ValveConnectorGridEdit: IValveConnectorGridEdit) => any;
}) => {
  const [canDuplex, setCanDuplex] = React.useState<Boolean>();
  const [valveConnectionID, setValveConnectionID] = React.useState<number>(0);
  const [ductID, setDuctID] = React.useState<number>(0);
  const [valveDiameterID, setValveDiameterID] = React.useState<number>(0);
  const [valveConnections, ducts, valveDiameters] = React.useMemo(() => {
    const valveConnections: { valveConnectionID: number; valveConnection: string }[] = [];
    const ducts: { ductID: number; duct: string }[] = [];
    const valveDiameters: { valveDiameterID: number; valveDiameter: string }[] = [];
    props.valveConnectors.forEach((a) => {
      if (!valveConnections.find((item) => item.valveConnectionID === a.valveConnectionID)) {
        valveConnections.push({ valveConnectionID: a.valveConnectionID, valveConnection: a.valveConnection });
      }
      if (!ducts.find((item) => item.ductID === a.ductID)) {
        ducts.push({ ductID: a.ductID, duct: a.duct });
      }
      if (!valveDiameters.find((item) => item.valveDiameterID === a.valveDiameterID)) {
        valveDiameters.push({ valveDiameterID: a.valveDiameterID, valveDiameter: a.valveDiameter });
      }
    });
    return [valveConnections, ducts, valveDiameters];
  }, [props.valveConnectors]);

  const keyboardNavigation = useKeyboardNavigation({
    rowdatas: props.valveConnectors,
    getFieldID: (item: IValveConnectorEdit) => item.valveConnectorID,
  });

  const [stickyRowTop, setStickyRowHeight, stickyColumnWidth, setColumnWidth] = useTableProps(3, 115, 3, 0);

  const headerRow: IRenderRowCallBacks = {
    valveConnectorName: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th
        key="valveConnectorName"
        stickyRowTop={stickyRowTop[0]}
        OnHeightChanged={(height: number) => setStickyRowHeight(1, height)}
        stickyColumnLeft={stickyColumnWidth[0]}
        OnWidthChanged={(height: number) => setColumnWidth(0, height)}>
        <ArrowDownwardIcon /> connector - grid
        <ArrowForwardIcon />
      </Th>
    ),
    article: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th key="article" stickyRowTop={stickyRowTop[0]}>
        article
      </Th>
    ),
    duplex: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th key="duplex" stickyRowTop={stickyRowTop[0]}>
        canDuplex
        <Checkbox
          checked={canDuplex === true}
          indeterminate={canDuplex === undefined}
          onChange={() =>
            setCanDuplex((prev) => {
              if (prev === undefined) return true;
              else if (prev === true) return false;
              else return undefined;
            })
          }
        />
      </Th>
    ),
    duct: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th key="duct" stickyRowTop={stickyRowTop[0]}>
        <Select
          placeholder="duct"
          value={ductID}
          onChange={(event: SelectChangeEvent<number>) => setDuctID(parseInt(event.target.value as string))}
          fullWidth>
          <MenuItem value="0">
            <em>duct</em>
          </MenuItem>
          {ducts.map((item) => (
            <MenuItem key={item.ductID} value={item.ductID}>
              {item.duct}
            </MenuItem>
          ))}
        </Select>
      </Th>
    ),
    valveConnection: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th key="valveConnector" stickyRowTop={stickyRowTop[0]}>
        <Select
          value={valveConnectionID}
          onChange={(event: SelectChangeEvent<number>) => setValveConnectionID(parseInt(event.target.value as string))}
          fullWidth>
          <MenuItem value="0">
            <em>connector</em>
          </MenuItem>
          {valveConnections.map((item) => (
            <MenuItem key={item.valveConnectionID} value={item.valveConnectionID}>
              {item.valveConnection}
            </MenuItem>
          ))}
        </Select>
      </Th>
    ),
    valveDiameter: (valveConnector: IValveConnectorEdit | undefined) => (
      <Th key="valveDiameter" stickyRowTop={stickyRowTop[0]}>
        <Select
          value={valveDiameterID}
          onChange={(event: SelectChangeEvent<number>) => setValveDiameterID(parseInt(event.target.value as string))}
          fullWidth>
          <MenuItem value="0">
            <em>diameter</em>
          </MenuItem>
          {valveDiameters.map((item) => (
            <MenuItem key={item.valveDiameterID} value={item.valveDiameterID}>
              {item.valveDiameter}
            </MenuItem>
          ))}
        </Select>
      </Th>
    ),
    valveConnectorGridConnector: (valveConnector: IValveConnectorEdit | undefined, valveGrid: IValveGridEdit) => (
      <Th key={'ValveGridCell' + valveGrid.valveGridID} stickyRowTop={stickyRowTop[0]}>
        {valveGrid.name}
        <br /> {valveGrid.articleNumber}
      </Th>
    ),
  };

  const dataRow: IRenderRowCallBacks = {
    valveConnectorName: (valveConnector: IValveConnectorEdit | undefined) => (
      <Td key="valveConnectorName" stickyColumnLeft={stickyColumnWidth[0]}>
        {valveConnector?.name}
      </Td>
    ),
    article: (valveConnector: IValveConnectorEdit | undefined) => (
      <Td key="article">{valveConnector?.articleNumber}</Td>
    ),
    duplex: (valveConnector: IValveConnectorEdit | undefined) => (
      <Td key="duplex">
        <Checkbox checked={valveConnector?.canDuplex} />
      </Td>
    ),
    duct: (valveConnector: IValveConnectorEdit | undefined) => <Td key="duct">{valveConnector?.duct}</Td>,
    valveConnection: (valveConnector: IValveConnectorEdit | undefined) => (
      <Td key="valveConnection">{valveConnector?.valveConnection}</Td>
    ),
    valveDiameter: (valveConnector: IValveConnectorEdit | undefined) => (
      <Td key="valveDiameter">{valveConnector?.valveDiameter}</Td>
    ),
    valveConnectorGridConnector: (valveConnector: IValveConnectorEdit | undefined, valveGrid: IValveGridEdit) =>
      valveConnector ? (
        <ValveGridCell
          key={'ValveGridCell' + valveConnector.valveConnectorID + '|' + valveGrid.valveGridID}
          state={props.state}
          valveGrid={valveGrid}
          valveConnector={valveConnector}
          valveConnectorGrids={props.valveConnectorGrids}
          valveConnectorGridUpdated={props.valveConnectorGridUpdated}
          keyboardNavigation={keyboardNavigation}
        />
      ) : null,
  };

  const filteredRows = React.useMemo(
    () =>
      props.valveConnectors.filter(
        (a) =>
          (canDuplex === undefined || a.canDuplex === canDuplex) &&
          (valveConnectionID === 0 || a.valveConnectionID === valveConnectionID) &&
          (ductID === 0 || a.ductID === ductID) &&
          (valveDiameterID === 0 || a.valveDiameterID === valveDiameterID)
      ),
    [canDuplex, valveConnectionID, ductID, valveDiameterID, props.valveConnectors]
  );

  return (
    <Table>
      <thead>
        <tr>
          {RenderRow(
            props.state,
            props.valveGrids.length,
            headerRow,
            undefined,
            props.valveGrids,
            props.valveConnectorGrids
          )}
        </tr>
      </thead>
      <tbody>
        {filteredRows.map((valveConnector) => (
          <tr key={valveConnector.valveConnectorID}>
            {RenderRow(
              props.state,
              props.valveGrids.length,
              dataRow,
              valveConnector,
              props.valveGrids,
              props.valveConnectorGrids
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ValveConnectorGridTable;
