import * as React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
const CheckBoxSelect = (props: {
  onChange: (value: number | boolean, field: string, error: string) => any;
  name: string;
  label: string;
  value: boolean;
  helperText?: string;
}) => {
  const { onChange } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className="CheckBoxerLabel"
      control={
        <Checkbox
          className={clsx(classes.checkbox)}
          checked={props.value}
          color="secondary"
          onChange={React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked, props.name, ''),
            [onChange, props.name]
          )}
          name={props.name}
        />
      }
      label={props.label}
    />
  );
};

export default CheckBoxSelect;
