import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { IProjectArticle } from 'application/project/api/ProjectArticle';
import { LoadingState } from 'projectRedux/shared';

interface initialProjectArticleEntityStateType {
  loading: LoadingState;
  valid: boolean;
}
export const initialProjectArticleEntityState: initialProjectArticleEntityStateType = {
  loading: 'idle',
  valid: false,
};
export declare interface ArticleLoadingEntityState<T> extends EntityState<T, number> {
  loading: LoadingState;
  valid: boolean;
}

export const projectArticleAdapter = createEntityAdapter<IProjectArticle, number>({
  selectId: (project) => project.projectArticleID,
});
