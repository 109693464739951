import { IExtraComponent } from 'application/components/api/ExtraComponent';
import { IProjectExtraComponent } from 'application/project/api/ProjectExtraComponent';
import { updateProjectExtraComponentThunk } from 'application/project/redux/thunks/AsyncProjectExtraComponent';
import React from 'react';
import { useDispatch } from 'react-redux';
import AmountInputField from 'components/controls/AmountInputField';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useProjectExtraComponentStyles = makeStyles((theme: Theme) => ({
  image: {
    height: 120,
  },

  imageContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  centered: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '100%',
    textAlign: 'center',
  },

  preImageContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    position: 'relative',
    width: 400,
    minHeight: 120,
  },

  editor: {
    width: 400,
  },

  group: {
    marginTop: 32,
    marginBottom: 32,
    display: 'flex',
  },
  ComponentContainer: {
    display: 'inline-block',
  },
}));

const newProjectExtraComponent = (extraComponent: IExtraComponent): IProjectExtraComponent => {
  return {
    projectExtraComponentID: 0,
    extraComponentID: extraComponent.extraComponentID,
    amount: undefined,
  };
};

export const ProjectExtraComponent = (props: {
  extraComponent: IExtraComponent;
  projectExtraComponent: IProjectExtraComponent | undefined;
}) => {
  const classes = useProjectExtraComponentStyles();
  const dispatch = useDispatch();

  const onChange = React.useCallback(
    (value: number | undefined, name: string, error: string) => {
      const newValue = props.projectExtraComponent
        ? { ...props.projectExtraComponent }
        : newProjectExtraComponent(props.extraComponent);
      newValue.amount = value;
      dispatch(updateProjectExtraComponentThunk(newValue) as any);
    },
    [dispatch, props.extraComponent, props.projectExtraComponent]
  );

  return (
    <div>
      <AmountInputField
        label={props.extraComponent.name}
        value={props.projectExtraComponent?.amount}
        className={classes.editor}
        name={props.extraComponent.extraComponentID.toString()}
        onChange={onChange}
      />
    </div>
  );
};

export default ProjectExtraComponent;
