import RegularPage from 'components/controls/RegularPage';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import useInfoDialog, { OpenInfoDialogProps } from 'components/dialog/useInfoDialog';
import React from 'react';
import { useGridStateData } from '../controls/hooks/useGridStateData';
import { datatype, GridInput } from '../controls/input/GridInput';
import SettingsGridTable from './table/SettingsGridTable';
import useSettingsGridData from './useSettingsGridData';
import { nameof } from 'application/shared/nameof';
import { ISettings } from 'application/editcomp/api/Settings';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

export interface IGridStateData {
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void;
}

export const initExpandedData = (
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void
): IGridStateData => {
  return {
    openInfoDialog: openInfoDialog,
  };
};
const Settings = () => {
  const data = useSettingsGridData();
  const { setSetting } = data;
  const [infoDialogState, openInfoDialog] = useInfoDialog();
  const gridStatePlaceHolder = useGridStateData(initExpandedData(openInfoDialog));
  const onCommit = React.useCallback(
    (row: any, name: any, value: any) => setSetting((prev) => ({ ...prev, [name]: value })),
    [setSetting]
  );

  const canAdd =
    data.setting.settingID.length > 0 &&
    !data.settings.find((a) => a.settingID.toLocaleLowerCase() === data.setting.settingID.toLocaleLowerCase());

  return (
    <RegularPage title="Settings">
      <InfoDialogEx {...infoDialogState} />
      <div>Settings</div>
      <div>
        name:{' '}
        <GridInput
          row={data.setting}
          name={nameof<ISettings>('settingID')}
          value={data.setting.settingID}
          onCommit={onCommit}
          datatype={datatype.string}
        />
      </div>
      <div>
        value:{' '}
        <GridInput
          row={data.setting}
          name={nameof<ISettings>('value')}
          value={data.setting.value}
          onCommit={onCommit}
          datatype={datatype.string}
        />
      </div>
      <div>
        encrypted:
        <Checkbox
          checked={data.setting.encrypted}
          onChange={(event, value) => onCommit(null, nameof<ISettings>('encrypted'), value)}
        />
      </div>
      <div>
        <Button disabled={!canAdd} color="primary" variant="outlined" onClick={() => data.saveSetting(data.setting)}>
          Add
        </Button>
      </div>
      <SettingsGridTable state={gridStatePlaceHolder} settings={data.settings} saveSetting={data.saveSetting} />
    </RegularPage>
  );
};

export default Settings;
