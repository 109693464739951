import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLanguageEdit } from 'application/editcomp/api/Language';
import { selectCompEditState } from 'projectRedux/store';

export const fetchAsyncLanguageEdit = createAsyncThunk(
  'editcomp/fetchAsyncLanguageEdit',
  async (data: {}, thunkAPI) => {
    return await getLanguageEdit();
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).languages.loading === 'init';
    },
  }
);
