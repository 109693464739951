import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDucts } from 'application/components/api/Duct';
import { selectComponentState } from 'projectRedux/store';

export const fetchAsyncDucts = createAsyncThunk(
  'components/fetchAsyncDucts',
  async (data: {}, thunkAPI) => {
    const response = await getDucts();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectComponentState(thunkAPI.getState()).ducts.loading === 'init';
    },
  }
);
