import { IPlenumConnection } from 'application/project/api/Connection';
import { projectSlice } from '../projectSlice';
import { updateConnectionThunk } from './AsyncConnectionUpdate';

export const setPlenumConnectionValue = (
  valveConnectionKey: string,
  id: string,
  plenumConnection: Partial<IPlenumConnection>
) => {
  return async (dispatch: any, getState: any) => {
    await dispatch(projectSlice.actions.updatePlenumConnection({ id: id, plenumConnection: plenumConnection }));
    await dispatch(updateConnectionThunk(valveConnectionKey));
  };
};
