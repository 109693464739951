import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlenumDatas, IPlenumData, updatePlenumData } from 'application/project/api/PlenumData';
import { selectProjectState } from 'projectRedux/store';

export const fetchAsyncPlenumDatas = createAsyncThunk(
  'project/plenumdata/fetchAsyncPlenumDatas',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getPlenumDatas(data.guid);
    return response;
  }
);

export const updateAsyncPlenumDatas = createAsyncThunk(
  'project/plenumdata/updateAsyncPlenumDatas',
  async (data: { guid: string; plenumdata: IPlenumData }, thunkAPI) => {
    const response = await updatePlenumData(data.guid, data.plenumdata.supply, data.plenumdata.index, data.plenumdata);
    return response;
  }
);

export const updateAsyncPlenumData = (plenumdata: IPlenumData) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      await dispatch(
        updateAsyncPlenumDatas({
          guid: projectState.activeProject?.guid,
          plenumdata: plenumdata,
        })
      );
    }
  };
};
