import { createSlice } from '@reduxjs/toolkit';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { nameof } from 'application/shared/nameof';
import {
  ConnectionDirty,
  IApiPlenumConnection,
  IApiSection,
  IApiSectionBend,
  IApiValveConnection,
  IPlenumConnection,
  ISection,
  IValveConnection,
} from '../api/Connection';
import { IEppDataComponent } from '../api/EppDataComponent';
import { valveConnectionAdapter } from './adapters/valveConnectionAdapter';
import { eppDataAdapter, GetEppDataID } from './adapters/eppDataAdapter';
import { eppDataComponentAdapter } from './adapters/eppDataComponentAdapter';
import { plenumConnectionAdapter, plenumConnectionKeyIntern } from './adapters/plenumConnectionAdapter';
import { createPlenumDataId, plenumDataAdapter } from './adapters/plenumDataAdapter';
import { projectArticleAdapter } from './adapters/projectArticleAdapter';
import { projectExtraComponentAdapter } from './adapters/projectExtraComponentAdapter';
import { sectionAdapter, sectionKey } from './adapters/sectionAdapter';
import { sectionBendAdapter } from './adapters/sectionBendAdapter';
import { initialState, projectState } from './projectState';
import { fetchAsyncConnections } from './thunks/AsyncConnection';
import { updateAsyncConnection } from './thunks/AsyncConnectionUpdate';
import { fetchAsyncEppDatas, updateAsyncEppDatas } from './thunks/AsyncEppData';
import { fetchAsyncEppDataComponents, updateAsyncEppDataComponent } from './thunks/AsyncEppDataComponent';
import { fetchAsyncPlenumDatas, updateAsyncPlenumDatas } from './thunks/AsyncPlenumData';
import { fetchAsyncProjectArticles } from './thunks/AsyncProjectArticle';
import {
  fetchAsyncProjectExtraComponents,
  updateAsyncProjectExtraComponent,
} from './thunks/AsyncProjectExtraComponent';

const apiSectionBend2redux = (
  apiSectionBend: IApiSectionBend,
  valveConnectionKey: string,
  plenumConnectionIndex: number,
  sectionIndex: number,
  state: projectState
) => {
  //sections eruit halen
  const { ...sectionBend } = { ...apiSectionBend };

  sectionBend.valveConnectionKey = valveConnectionKey;
  sectionBend.plenumConnectionIndex = plenumConnectionIndex;
  sectionBend.sectionIndex = sectionIndex;

  //bijwerken/insert van de plenumConnection
  sectionBendAdapter.upsertOne(state.sectionbends, sectionBend);
};

const apiSection2redux = (
  apiSection: IApiSection,
  valveConnectionKey: string,
  plenumConnectionIndex: number,
  state: projectState
) => {
  //sections eruit halen
  const { bends, ...section } = apiSection;

  section.valveConnectionKey = valveConnectionKey;
  section.plenumConnectionIndex = plenumConnectionIndex;

  //bijwerken/insert van de plenumConnection
  sectionAdapter.upsertOne(state.sections, section);

  bends.forEach((bend) => {
    apiSectionBend2redux(bend, valveConnectionKey, plenumConnectionIndex, section.indexNumber, state);
  });
};

const apiPlenumConnection2redux = (
  apiPlenumConnection: IApiPlenumConnection,
  valveConnectionKey: string,
  supply: PlenumDataSupply,
  state: projectState
) => {
  //sections eruit halen
  const { sections, ...plenumConnection } = apiPlenumConnection;

  plenumConnection.valveConnectionKey = valveConnectionKey;
  plenumConnection.supply = supply;

  //bijwerken/insert van de plenumConnection
  plenumConnectionAdapter.upsertOne(state.plenumConnections, plenumConnection);

  sections.forEach((section) => {
    apiSection2redux(section, valveConnectionKey, plenumConnection.indexNumber, state);
  });
};

const apiValveConnection2redux = (apiConnection: IApiValveConnection, state: projectState) => {
  //plenumConnections eruit halen
  const { plenumConnections, ...valveConnection } = apiConnection;

  const currentConnections = valveConnectionAdapter.getSelectors().selectAll(state.valveConnections);
  const currentItem = currentConnections.find((a) => a.valveConnectionID === valveConnection.valveConnectionID);
  if (currentItem) {
    valveConnection.valveConnectionKey = currentItem.valveConnectionKey;
  }
  valveConnection.dirty = ConnectionDirty.saved;

  if (!valveConnection.valveConnectionKey) {
    state.connectionKeyCounter = state.connectionKeyCounter + 1;
    valveConnection.valveConnectionKey = state.connectionKeyCounter.toString();
    valveConnectionAdapter.addOne(state.valveConnections, valveConnection);
  } else {
    valveConnectionAdapter.updateOne(state.valveConnections, {
      id: valveConnection.valveConnectionKey,
      changes: valveConnection,
    });
  }

  plenumConnections.forEach((plenumConnection) => {
    apiPlenumConnection2redux(plenumConnection, valveConnection.valveConnectionKey, valveConnection.supply, state);
  });
};

//  functions
export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    activeProjectChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.activeProject = action.payload;
      projectArticleAdapter.removeAll(state.projectArticles);
      state.projectArticles.valid = false;
    },
    setActiveConnection(state, action) {
      plenumDataAdapter.updateOne(state.plenumdatas, {
        id: createPlenumDataId(action.payload),
        changes: { activeConnectionKey: action.payload.valveConnectionKey },
      });
    },
    LanguageChanged(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      projectArticleAdapter.removeAll(state.projectArticles);
      state.projectArticles.valid = false;
    },
    updateSectionBend(state, action) {
      sectionBendAdapter.upsertOne(state.sectionbends, {
        valveConnectionKey: (action.payload.section as ISection).valveConnectionKey,
        plenumConnectionIndex: (action.payload.section as ISection).plenumConnectionIndex,
        sectionIndex: (action.payload.section as ISection).indexNumber,
        bendID: action.payload.bendID,
        amount: action.payload.amount,
      });
    },
    updateSection(state, action) {
      if (action.payload.name === nameof<ISection>('ductAdapterID')) {
        //we hebben een adapter, of niet
        const item = sectionAdapter.getSelectors().selectById(state.sections, action.payload.id);
        if (!item) return;
        const sections = sectionAdapter
          .getSelectors()
          .selectAll(state.sections)
          .filter(
            (a) =>
              a.valveConnectionKey === item?.valveConnectionKey &&
              a.plenumConnectionIndex === item.plenumConnectionIndex
          );

        if (!item.ductAdapterID && action.payload.value > 0 && sections.indexOf(item) === sections.length - 1) {
          //we hebben op de laatste positie een adapter
          //dan een nieuwe sectie toevoegen
          sectionAdapter.addOne(state.sections, {
            valveConnectionKey: item.valveConnectionKey,
            plenumConnectionIndex: item.plenumConnectionIndex,
            indexNumber: item.indexNumber + 1,
          } as ISection);
        } else if (item.ductAdapterID && !action.payload.value && sections.indexOf(item) < sections.length - 1) {
          //we hebben verwijderen op niet de laatste positie de adapter
          //dan alles secties hierna weggooien
          sectionAdapter.removeMany(
            state.sections,
            sections.splice(sections.indexOf(item) + 1).map((a) => sectionKey(a))
          );
        }
      }
      sectionAdapter.updateOne(state.sections, {
        id: action.payload.id,
        changes: { [action.payload.name]: action.payload.value },
      });
    },
    updatePlenumConnection(state, action) {
      plenumConnectionAdapter.updateOne(state.plenumConnections, {
        id: action.payload.id,
        changes: action.payload.plenumConnection,
      });
    },
    addValveConnection(state, action) {
      state.connectionKeyCounter = state.connectionKeyCounter + 1;

      const valveConnection: IValveConnection = {
        valveConnectionKey: state.connectionKeyCounter.toString(),
        supply: action.payload.supply,
        plenumIndex: action.payload.plenumIndex,
        valveConnectionID: undefined,
        airflowRate: undefined,
        customValveResistance: undefined,
        valveConnectorGridID: action.payload.valveConnectorGridID,
        remove: undefined,
        description: '',
        dual: false,
        hasFilter: false,
        dirty: ConnectionDirty.dirty,
      };
      const plenumConnection: IPlenumConnection = {
        valveConnectionKey: valveConnection.valveConnectionKey,
        supply: valveConnection.supply,
        finalAirflowRate: undefined,
        connectionIndex: undefined,
        connectorRightAngled: false,
        restriction: '',
        indexNumber: 0,
      };
      const section: ISection = {
        valveConnectionKey: valveConnection.valveConnectionKey,
        plenumConnectionIndex: plenumConnection.indexNumber,
        ductID: action.payload.ductID,
        ductLenght: undefined,
        indexNumber: 0,
        ductAdapterID: undefined,
      };

      plenumDataAdapter.updateOne(state.plenumdatas, {
        id: createPlenumDataId(action.payload),
        changes: { activeConnectionKey: valveConnection.valveConnectionKey },
      });

      valveConnectionAdapter.addOne(state.valveConnections, valveConnection);
      plenumConnectionAdapter.addOne(state.plenumConnections, plenumConnection);
      sectionAdapter.addOne(state.sections, section);
    },
    deleteValveConnection(state, action) {
      valveConnectionAdapter.removeOne(state.valveConnections, action.payload.id);
    },
    updateValveConnection(state, action) {
      const valveConnection = valveConnectionAdapter
        .getSelectors()
        .selectById(state.valveConnections, action.payload.id);
      if (!valveConnection) return;

      if (action.payload.name === nameof<IValveConnection>('dual')) {
        const plenumConnections = plenumConnectionAdapter
          .getSelectors()
          .selectAll(state.plenumConnections)
          .filter((a) => a.valveConnectionKey === valveConnection.valveConnectionKey);

        if (plenumConnections.length === 1 && action.payload.value) {
          plenumConnectionAdapter.addOne(state.plenumConnections, {
            valveConnectionKey: valveConnection.valveConnectionKey,
            indexNumber: 1,
          } as IPlenumConnection);
        } else if (plenumConnections.length === 2 && !action.payload.value) {
          plenumConnectionAdapter.removeOne(
            state.plenumConnections,
            plenumConnectionKeyIntern(valveConnection.valveConnectionKey, 1)
          );
        }
      }

      valveConnectionAdapter.updateOne(state.valveConnections, {
        id: action.payload.id,
        changes: { [action.payload.name]: action.payload.value },
      });
    },
  },
  extraReducers: (builder) => {
    builder //fetchEnginePriceLists
      //plenumdata
      .addCase(fetchAsyncPlenumDatas.pending, (state, action) => {
        state.plenumdatas.loading = 'loading';
      })
      .addCase(fetchAsyncPlenumDatas.fulfilled, (state, action) => {
        plenumDataAdapter.setAll(state.plenumdatas, action.payload);
        state.plenumdatas.loading = 'idle';
      })
      .addCase(updateAsyncPlenumDatas.pending, (state, action) => {
        state.plenumdatas.saving = 'loading';
      })
      .addCase(updateAsyncPlenumDatas.fulfilled, (state, action) => {
        plenumDataAdapter.updateOne(state.plenumdatas, {
          id: createPlenumDataId(action.meta.arg.plenumdata),
          changes: { plenumID: action.payload.plenumID },
        });
        state.plenumdatas.saving = 'idle';
        state.projectArticles.valid = false;
      })
      //fetch connections
      .addCase(fetchAsyncConnections.pending, (state, action) => {
        state.valveConnections.loading = 'loading';
      })
      .addCase(fetchAsyncConnections.fulfilled, (state, action) => {
        valveConnectionAdapter.removeAll(state.valveConnections);
        plenumConnectionAdapter.removeAll(state.plenumConnections);
        sectionAdapter.removeAll(state.sections);
        sectionBendAdapter.removeAll(state.sectionbends);
        action.payload.forEach((connection: IApiValveConnection) => {
          apiValveConnection2redux(connection, state);
        });
        state.valveConnections.loading = 'idle';
      })
      //update connection
      .addCase(updateAsyncConnection.pending, (state, action) => {
        state.valveConnections.saving = 'loading';
        if (!action.meta.arg.apiConnection.remove) {
          valveConnectionAdapter.updateOne(state.valveConnections, {
            id: action.meta.arg.apiConnection.valveConnectionKey,
            changes: { dirty: ConnectionDirty.saving },
          });
        }
      })
      .addCase(updateAsyncConnection.fulfilled, (state, action) => {
        //plenumdata vervangen
        state.projectArticles.valid = false;
        plenumDataAdapter.updateOne(state.plenumdatas, {
          id: createPlenumDataId(action.payload.plenumData),
          changes: action.payload.plenumData,
        });

        if (!action.meta.arg.apiConnection.remove) {
          action.payload.connections.forEach((connection: IApiValveConnection) => {
            apiValveConnection2redux(connection, state);
          });
        }
        state.valveConnections.loading = 'idle';
      })
      //eppdata
      .addCase(fetchAsyncEppDatas.pending, (state, action) => {
        state.eppdatas.loading = 'loading';
      })
      .addCase(fetchAsyncEppDatas.fulfilled, (state, action) => {
        eppDataAdapter.setAll(state.eppdatas, action.payload);
        state.eppdatas.loading = 'idle';
      })
      //update eppdata
      .addCase(updateAsyncEppDatas.pending, (state, action) => {
        state.eppdatas.saving = 'loading';
      })
      .addCase(updateAsyncEppDatas.fulfilled, (state, action) => {
        //create a copy (IEppData)
        const changedItem = { ...action.payload };
        const remoteEppDataComponents: IEppDataComponent[] = changedItem.eppDataComponents;
        delete changedItem.eppDataComponents;

        eppDataAdapter.updateOne(state.eppdatas, {
          id: GetEppDataID(action.meta.arg.eppdata),
          changes: changedItem,
        });

        //ale eppdatacomponents ophalen
        const eppDataComponentIDs = eppDataComponentAdapter
          .getSelectors()
          .selectAll(state.eppdatacomponents)
          .filter((a) => a.supply === action.meta.arg.eppdata.supply && a.inside === action.meta.arg.eppdata.inside)
          .map((a) => a.eppDataComponentID);
        //kijken welke er weg is, of aangepast.
        eppDataComponentAdapter.removeMany(state.eppdatacomponents, eppDataComponentIDs);
        eppDataComponentAdapter.addMany(state.eppdatacomponents, remoteEppDataComponents);

        state.projectArticles.valid = false;
        state.eppdatas.saving = 'idle';
      })
      //eppdatacomponents
      .addCase(fetchAsyncEppDataComponents.pending, (state, action) => {
        state.eppdatacomponents.loading = 'loading';
      })
      .addCase(fetchAsyncEppDataComponents.fulfilled, (state, action) => {
        eppDataComponentAdapter.setAll(state.eppdatacomponents, action.payload);
        state.eppdatacomponents.loading = 'idle';
      })
      //update eppdatacomponents
      .addCase(updateAsyncEppDataComponent.pending, (state, action) => {
        state.eppdatacomponents.saving = 'loading';
      })
      .addCase(updateAsyncEppDataComponent.fulfilled, (state, action) => {
        eppDataComponentAdapter.upsertOne(state.eppdatacomponents, action.payload);
        state.projectArticles.valid = false;
        state.eppdatacomponents.saving = 'idle';
      })
      //ProjectExtraComponents
      .addCase(fetchAsyncProjectExtraComponents.pending, (state, action) => {
        state.projectExtraComponents.loading = 'loading';
      })
      .addCase(fetchAsyncProjectExtraComponents.fulfilled, (state, action) => {
        projectExtraComponentAdapter.setAll(state.projectExtraComponents, action.payload);
        state.projectExtraComponents.loading = 'idle';
      })
      //update ProjectExtraComponents
      .addCase(updateAsyncProjectExtraComponent.pending, (state, action) => {
        state.projectExtraComponents.saving = 'loading';
      })
      .addCase(updateAsyncProjectExtraComponent.fulfilled, (state, action) => {
        projectExtraComponentAdapter.upsertOne(state.projectExtraComponents, action.payload);
        state.projectArticles.valid = false;
        state.projectExtraComponents.saving = 'idle';
      })
      //projectArticleAdapter
      .addCase(fetchAsyncProjectArticles.pending, (state, action) => {
        projectArticleAdapter.removeAll(state.projectArticles);
        state.projectArticles.loading = 'loading';
      })
      .addCase(fetchAsyncProjectArticles.fulfilled, (state, action) => {
        projectArticleAdapter.setAll(state.projectArticles, action.payload);
        state.projectArticles.valid = true;
        state.projectArticles.loading = 'idle';
      });
  },
});

export default projectSlice.reducer;
