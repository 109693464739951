import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './EditorGrid/styles';
import ApplicationBar from './EditorGrid/ApplicationBar';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { pages_project_list, pages_project_setactive, useRoutes } from './EditorGrid/useRoutes';
import clsx from 'clsx';
import { asyncInitEditor } from 'application/project/redux/thunks/AsyncInitEditor';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//import mainLogo from 'image/mggroup.png';
import mainLogo from 'image/burgerhout.png';

import authService from 'components/api-authorization/AuthorizeService';
import EditorSwitch from './EditorGrid/EditorSwitch';
import { IEditorTabs } from './EditorGrid/IEditorTabs';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

//https://material-ui.com/components/drawers/#drawer

export const Editor = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [roles, setRoles] = React.useState<string[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    authService.getRoles().then((resRoles) => {
      if (componentIsMounted.current) setRoles(resRoles);
    });
  }, []);

  const componentIsMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount

  React.useEffect(() => {
    //initieren van de editor
    dispatch(asyncInitEditor() as any);
  }, [dispatch]);

  const project = useSelector(selectActiveProject);

  const { populatedEditorTabs, editorTabs, image, onPrev, canPrev, onNext, canNext } = useRoutes(
    project?.ventilationTypeID === 2,
    roles
  );

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  if (!project) {
    const activeProjectGuid = localStorage.getItem('activeProjectGuid');
    if (activeProjectGuid != null && activeProjectGuid?.length > 0) {
      return (
        <Redirect
          to={pages_project_setactive + activeProjectGuid + '?url=' + encodeURIComponent(location.pathname)}
          push={false}
        />
      );
    } else {
      return <Redirect to={pages_project_list} push={false} />;
    }
  }

  const tabFilter = (item: IEditorTabs) => {
    const visible =
      !!item.icon || (!!item.urlGroup && location.pathname.toLowerCase().startsWith(item.urlGroup.toLowerCase()));
    return visible;
  };
  const drawer = (
    <div>
      <a href={pages_project_list}>
        <div className={(classes.toolbar, classes.logoHost)}>
          <img src={mainLogo} className={classes.logo} alt={'logo'} />
        </div>
      </a>
      {editorTabs.map((tabGroup, index2) => {
        return (
          <React.Fragment key={'group' + index2}>
            {index2 === 0 ? <Divider style={{ borderColor: theme.palette.primary.main, opacity: 1 }} /> : null}
            <List className={classes.List}>
              {tabGroup.filter(tabFilter).map((item) => {
                const active = location.pathname.toLowerCase() === (item.path || '').toLowerCase() ? 'active' : null;
                return (
                  <Link to={item.path || ''} key={item.path} className={clsx(classes.menu)}>
                    <ListItem key={item.title} className={clsx(classes.menuItem, active)}>
                      <ListItemIcon className={clsx(classes.menuIcon, active)}>
                        {item.icon && <img src={item.icon} alt={''} className={clsx(classes.menuImage)} />}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        className={clsx(classes.menuText, active, item.icon ? null : 'noIcon')}
                      />
                      {active && <ChevronRightIcon color="secondary" />}
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </React.Fragment>
        );
      })}
      {image ? <img style={{ width: '100%' }} alt="map" src={image} /> : null}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApplicationBar
        project={project}
        handleDrawerToggle={handleDrawerToggle}
        canPrev={canPrev}
        onPrev={onPrev}
        onNext={onNext}
        canNext={canNext}
      />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar}></div>
        <div className="tabs">
          <EditorSwitch
            theEditorTabs={populatedEditorTabs}
            image={image}
            canPrev={canPrev}
            onPrev={onPrev}
            onNext={onNext}
            canNext={canNext}
          />
        </div>
      </main>
    </div>
  );
};

export default Editor;
