import Button from '@mui/material/Button';
import { getInstallationPlanXlsx } from 'application/project/api/InstallationPlan';
import {
  projectArticlesLoading,
  selectAllProjectArticles,
} from 'application/project/redux/selectors/projectArticleSelectors';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import { asyncInitProjectArticles } from 'application/project/redux/thunks/AsyncProjectArticle';
import EditorPage from 'components/controls/EditorPage';
import { lastUpdate } from 'components/controls/TextInputField';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ComponentList = (props: { onNext: () => any }) => {
  const dispatch = useDispatch();

  const project = useSelector(selectActiveProject);
  const [downLoading, setDownLoading] = React.useState(false);
  const { t } = useTranslation(['componentlist']);

  React.useEffect(() => {
    dispatch(asyncInitProjectArticles(lastUpdate.LastDebouce) as any);
  }, [dispatch]);

  const projectArticles = useSelector(selectAllProjectArticles);
  const articlesLoading = useSelector(projectArticlesLoading);

  return (
    <EditorPage title={t('title', 'Component list')} onNext={props.onNext}>
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={downLoading}
          onClick={React.useCallback(() => {
            if (project) {
              setDownLoading(true);
              getInstallationPlanXlsx(project.guid).then((result) => {
                setDownLoading(false);
              });
            }
          }, [project])}>
          {downLoading ? t('downloading', 'downloading ...') : t('downloadxlsx', 'download xlsx')}
        </Button>
      </div>

      <div>
        {articlesLoading ? (
          <div>{t('Componentlistloading', 'the components list is being compiled.')}</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>{t('amount', 'Amount')}</th>
                <th>{t('articlenumber', 'Article number')}</th>
                <th>{t('article', 'Article')}</th>
              </tr>
            </thead>
            <tbody>
              {projectArticles.map((projectArticle) => (
                <tr key={projectArticle.projectArticleID}>
                  <td>
                    {projectArticle.amount}{' '}
                    {projectArticle.quantityPerItem > 0 ? '(' + projectArticle.quantity + 'm)' : ''}
                  </td>
                  <td>{projectArticle.articleNumber}</td>
                  <td>{projectArticle.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </EditorPage>
  );
};

export default ComponentList;
