import React from 'react';
import {
  getValveConnectorGridImage,
  uploadValveConnectorGridImage,
} from 'application/components/api/ValveConnectorGrid';
import { IValveConnectorGridEdit } from 'application/editcomp/api/ValveConnectorGrid/ValveConnectorGridEdit';

const ConnectorGridImage = (props: {
  valveConnectorGridID: number;
  gridImageID: number | undefined;
  valveConnectorGridUpdated: (ValveConnectorGridEdit: IValveConnectorGridEdit) => any;
}) => {
  const inputRef = React.useRef(null);

  const onChangeFile = (event: any) => {
    uploadValveConnectorGridImage(props.valveConnectorGridID, event.target.files[0], event.target.files[0].name).then(
      (valveConnectorGrid) => {
        props.valveConnectorGridUpdated(valveConnectorGrid);
      }
    );
  };

  return (
    <div
      style={{
        height: 52,
        width: 52,
        padding: 2,
        display: 'inline-block',
        cursor: 'pointer',
      }}
      onClick={(e: any) => {
        const temp: any = inputRef.current;
        if (temp) {
          temp.click();
        }
      }}>
      <input ref={inputRef} style={{ display: 'none' }} type="file" onChange={onChangeFile} />
      {props.gridImageID ? (
        <img
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          src={getValveConnectorGridImage(props.valveConnectorGridID, props.gridImageID)}
          alt="valve grid"
        />
      ) : null}
    </div>
  );
};

export default ConnectorGridImage;
