import { useGlobalStyles } from 'application/shared/styles';
import * as React from 'react';
import PageTitle from './PageTitle';
import Paper from '@mui/material/Paper';

const EditorPage = (props: { children?: any; title: string; onNext?: () => any }) => {
  const classes = useGlobalStyles();

  return (
    <div className={classes.regularContent}>
      <PageTitle title={props.title} onNext={props.onNext} />
      <Paper className={classes.paper}>{props.children}</Paper>
      {/* {props.onNext ? <NextButton onNext={props.onNext} /> : null} */}
    </div>
  );
};

export default EditorPage;
