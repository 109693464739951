import { IValveConnection } from 'application/project/api/Connection';
import TextInputField from 'components/controls/TextInputField';
import * as React from 'react';
import { nameof } from 'application/shared/nameof';
import connectionStyles from './controls/connectionStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const PartDescription = (props: {
  connection: IValveConnection;
  onChange: (value: string, field: string, error: string) => any;
}) => {
  const classes = connectionStyles();
  const { onChange, connection } = props;
  const { t } = useTranslation(['roomeditor']);

  return (
    <div className={clsx(classes.partBlock)}>
      <TextInputField
        label={t('roomdescription', 'description')}
        name={nameof<IValveConnection>('description')}
        fullWidth
        debounce={true}
        required
        value={connection.description}
        onChange={React.useCallback(
          (value: string, name: string, error: string) => {
            onChange(value, name, error);
          },
          [onChange]
        )}
      />
    </div>
  );
};

export default PartDescription;
