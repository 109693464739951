import { createSelector } from '@reduxjs/toolkit';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectComponentState } from 'projectRedux/store';
import { plenumAdapter } from '../adapters/plenumAdapter';

export const {
  selectAll: selectAllPlenums,
  selectById: selectPlenumById,
  selectEntities: selectPlenumEntities,
} = plenumAdapter.getSelectors((state) => selectComponentState(state).plenums);

export const makeSelectPlenums = () => {
  return createSelector(
    // First, pass one or more "input selector" functions:
    (state: any, supply: PlenumDataSupply, ductID: number | undefined) => supply,
    (state: any, supply: PlenumDataSupply, ductID: number | undefined) => ductID,
    selectAllPlenums,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (supply, ductID, plenums) =>
      plenums.filter(
        (a) =>
          a.ductID === ductID &&
          ((supply === PlenumDataSupply.supply && a.canSupply) ||
            (supply === PlenumDataSupply.extraction && a.canExtraction))
      )
  );
};
