import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionDirty, IValveConnection, SECTIONS_AS_PLENUMCONNECTION_0 } from 'application/project/api/Connection';
import connectionStyles from './parts/controls/connectionStyles';
import PartDescription from './parts/PartDescription';
import PartDebiet from './parts/PartDebiet';
import PartDuctAndLength from './parts/PartDuctAndLength';
import PartDuctElbows from './parts/PartDuctElbows';
import PartAdapter from './parts/PartAdapter';
import PartValve from './parts/PartValve';
import { IPlenumData } from 'application/project/api/PlenumData';
import { useGlobalStyles } from 'application/shared/styles';
import clsx from 'clsx';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { selectPlenumById } from 'application/components/redux/selectors/plenumSelector';
import RenderParts from './RenderParts';
import { makeSelectSectionsByConnection } from 'application/project/redux/selectors/sectionSelectors';
import PartPlenumConnections from './parts/PartPlenumConnections';
import { ValveConnectionSetValue } from 'application/project/redux/thunks/ValveConnectionSetValue';
import { ValveConnectionDelete } from 'application/project/redux/thunks/ValveConnectionDelete';
import useConnectionFlowLimits from './useConnectionFlowLimits';
import { projectState } from 'application/project/redux/projectState';
import { selectProjectState } from 'projectRedux/store';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

export const ConnectionEdit = (props: {
  connection: IValveConnection;
  plenumdata: IPlenumData;
  setActive: (key: string) => any;
  setValid: (key: string, valid: boolean) => any;
  isActive: boolean;
  isValid: boolean;
  getConnectionError: (connection: IValveConnection, projectState: projectState) => string;
}) => {
  const classes = connectionStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { connection } = props;
  const { valveConnectionKey } = connection;
  const plenum = useSelector((state) => selectPlenumById(state, props.plenumdata?.plenumID || 0));
  const { ductMin, ductWarn, ductMax } = useConnectionFlowLimits(props.connection.valveConnectionKey);
  const selectSectionsByConnection = React.useMemo(makeSelectSectionsByConnection, []);
  const projectState = useSelector(selectProjectState);
  const sections = useSelector((state) =>
    selectSectionsByConnection(state, valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0)
  );
  const { setActive: propsSetActive, getConnectionError } = props;

  const onValveConnectionChange = React.useCallback(
    (value: number | string | boolean | undefined, field: string, error: string) =>
      dispatch(ValveConnectionSetValue(valveConnectionKey, field, value) as any),
    [dispatch, valveConnectionKey]
  );

  const onDelete = React.useCallback(
    (event: any) => {
      dispatch(ValveConnectionDelete(valveConnectionKey) as any);
    },
    [dispatch, valveConnectionKey]
  );

  const setActive = React.useCallback(() => propsSetActive(valveConnectionKey), [valveConnectionKey, propsSetActive]);

  const partPlenumConnections = <PartPlenumConnections valveConnection={connection} plenumdata={props.plenumdata} />;
  const partDescription = <PartDescription connection={connection} onChange={onValveConnectionChange} />;
  const partDebiet = (
    <PartDebiet
      connection={connection}
      plenumdata={props.plenumdata}
      onChange={onValveConnectionChange}
      minAirflowRate={ductMin}
      maxAirflowRate={ductMax}
      warnAirflowRate={ductWarn}
      plenumMaxAirflowRate={plenum?.maxFlowRate || 0}
      plenumAirflowRate={(props.plenumdata.airflowRate || 0) - (connection.airflowRate || 0)}
    />
  );

  const partSections = sections.map((section) => [
    <PartDuctAndLength plenumdata={props.plenumdata} connection={connection} section={section} />,
    <PartDuctElbows connection={connection} section={section} />,
    <PartAdapter connection={connection} section={section} />,
  ]);

  const partValve = (
    <PartValve connection={connection} plenumdata={props.plenumdata} onChange={onValveConnectionChange} />
  );

  return (
    <form className={classes.root}>
      <Paper
        className={clsx(
          globalClasses.paper,
          classes.connectionRoot,
          props.isActive ? 'actief' : null,
          'ConnectionDirty' + ConnectionDirty[connection.dirty],
          props.isValid === false || !!getConnectionError(connection, projectState) ? 'connectionError' : null
        )}
        onClick={setActive}>
        <RenderParts
          partPlenumConnections={partPlenumConnections}
          partDescription={partDescription}
          partDebiet={partDebiet}
          partSections={partSections}
          partValve={partValve}
        />
        <IconButton className={classes.deleteButton} onClick={onDelete}>
          <HighlightOff className={classes.deleteIcon} />
        </IconButton>
      </Paper>
    </form>
  );
};

export default ConnectionEdit;
