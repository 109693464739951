import * as React from 'react';
import { ISection, IValveConnection } from 'application/project/api/Connection';
import { nameof } from 'application/shared/nameof';
import { selectAllDuctAdapters } from 'application/components/redux/selectors/ductAdapterSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Selector from 'components/controls/Selector';
import { SetSectionValue } from 'application/project/redux/thunks/SetSectionValue';
import { sectionKey } from 'application/project/redux/adapters/sectionAdapter';
import MenuItem from '@mui/material/MenuItem';

const PartAdapter = (props: { connection: IValveConnection; section: ISection }) => {
  const { section } = props;
  const dispatch = useDispatch();
  const allAdapters = useSelector(selectAllDuctAdapters);
  const { t } = useTranslation(['roomeditor']);

  const onChange = React.useCallback(
    (value: number | undefined, field: string, error: string) =>
      dispatch(
        SetSectionValue(section.valveConnectionKey, sectionKey(section), field, value === 0 ? undefined : value) as any
      ),
    [dispatch, section]
  );

  const adapters = React.useMemo(
    () => [
      { ductAdapterID: 0, name: 'no adapter' },
      ...allAdapters.filter((a) => a.ductID1 === props.section.ductID || a.ductID2 === props.section.ductID),
    ],
    [allAdapters, props.section.ductID]
  );

  return props.section.indexNumber <= 1 || props.section.ductAdapterID ? (
    <Selector
      fullWidth
      label={t('ductadapter', 'duct adapter')}
      helperText={t('ductadapterHelper', 'select duct adapter')}
      name={nameof<ISection>('ductAdapterID')}
      value={props.section.ductAdapterID}
      displayEmpty
      onChange={onChange}
      items={adapters.map((adapter) => (
        <MenuItem key={adapter.ductAdapterID} value={adapter.ductAdapterID?.toString()}>
          {adapter.name}
        </MenuItem>
      ))}
    />
  ) : null;
};

export default PartAdapter;
