import { getEppTerminalTypeEdit, IEppTerminalTypeEdit } from 'application/editcomp/api/EppTerminalType';
import {
  enTranslationType,
  getTranslationEdit,
  getTranslationsFromRecord,
  ITranslationEdit,
} from 'application/editcomp/api/Translation';
import { selectAllLanguageEdit } from 'application/editcomp/redux/selectors/languageEditSelector';
import { fetchAsyncLanguageEdit } from 'application/editcomp/redux/thunks/fetchAsyncLanguageEdit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface IEppTerminalTypeEditEx extends IEppTerminalTypeEdit {
  translations: { [id: number]: ITranslationEdit };
}
const useEppTerminalTypeGridData = () => {
  const dispatch = useDispatch();
  const [eppTerminalTypes, setEppTerminalTypes] = React.useState<IEppTerminalTypeEdit[]>([]);
  const [translations, setTranslations] = React.useState<ITranslationEdit[]>([]);

  React.useEffect(() => {
    dispatch(fetchAsyncLanguageEdit({}) as any);
    getEppTerminalTypeEdit().then((result) => setEppTerminalTypes(result));
    getTranslationEdit({ translationTypeID: enTranslationType.eppTerminalTypeName }).then((result) =>
      setTranslations(result)
    );
  }, [dispatch]);

  const eppTerminalTypeEditUpdated = React.useCallback(
    (newitem: IEppTerminalTypeEdit) => {
      setEppTerminalTypes((items) =>
        items.map((item) => {
          if (newitem.eppTerminalTypeID === item.eppTerminalTypeID) {
            return newitem;
          } else {
            return item;
          }
        })
      );
    },
    [setEppTerminalTypes]
  );

  const languages = useSelector(selectAllLanguageEdit);

  return React.useMemo(
    () => ({
      articles: eppTerminalTypes.map(
        (item) =>
          ({
            ...item,
            translations: getTranslationsFromRecord(item.eppTerminalTypeID, translations),
          } as IEppTerminalTypeEditEx)
      ),
      eppTerminalTypeEditUpdated,
      languages,
    }),
    [eppTerminalTypes, translations, eppTerminalTypeEditUpdated, languages]
  );
};

export default useEppTerminalTypeGridData;
