import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useULStyles = makeStyles((theme: Theme) => ({
  root_ul: {
    paddingLeft: 0,
    color: theme.palette.text.primary,

    listStyle: 'none',
    marginTop: 0,
    marginBottom: '1rem',

    '& >li': {
      position: 'relative',
      marginBottom: 10,
      paddingLeft: 25,

      '&::before': {
        position: 'absolute',
        left: 0,
        content: '""',
        top: 5,
        height: 10,
        width: 10,
        display: 'inline-block',
        background: theme.palette.secondary.main,
        marginRight: 15,
      },
      '&::marker': {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        color: '#747373',
        fontSize: 14,
        lineHeight: 20,
      },
    },
  },
  root_li: {},
  arrow: {
    fontWeight: 400,
    fontSize: 16,
    height: 14,
    lineHeight: 14,
    top: 17,
    fontFamily: 'FontAwesome',
    position: 'absolute',
    right: 20,
    textAlign: 'center',
  },
}));

export const HomeUL = (props: { children: any }) => {
  const classes = useULStyles();

  return <ul className={classes.root_ul}>{props.children}</ul>;
};
