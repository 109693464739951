import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectArticles } from 'application/project/api/ProjectArticle';
import { selectProjectState } from 'projectRedux/store';

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchAsyncProjectArticles = createAsyncThunk(
  'project/extraComponent/fetchAsyncProjectArticles',
  async (data: { guid: string; delay: number }, thunkAPI) => {
    if (data.delay > 0) {
      await timeout(data.delay);
    }
    return await getProjectArticles(data.guid);
  }
);

export const asyncInitProjectArticles = (lastUpdate: Date) => {
  return (dispatch: any, getState: any) => {
    var difference = new Date().getTime() - lastUpdate.getTime();
    const compare = 2500;
    const delay = Math.max(0, compare - difference);

    const projectState = selectProjectState(getState());
    if ((!projectState.projectArticles.valid || delay > 0) && projectState.activeProject) {
      dispatch(fetchAsyncProjectArticles({ guid: projectState.activeProject.guid, delay: delay }));
    }
  };
};
