import MenuItem from '@mui/material/MenuItem';
import { IDuct } from 'application/components/api/Duct';
import { selectDuctAdapterEntities } from 'application/components/redux/selectors/ductAdapterSelector';
import { enDuctType, selectAllDucts } from 'application/components/redux/selectors/ductSelector';
import { selectPlenumById } from 'application/components/redux/selectors/plenumSelector';
import { ISection, IValveConnection, SECTIONS_AS_PLENUMCONNECTION_0 } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { makeSelectSectionsByConnection } from 'application/project/redux/selectors/sectionSelectors';
import { nameof } from 'application/shared/nameof';
import Selector from 'components/controls/Selector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DuctSelect = (props: {
  plenumdata: IPlenumData;
  connection: IValveConnection;
  section: ISection;
  onChange: (value: number | undefined, field: string, error: string) => any;
}) => {
  const { onChange, section } = props;
  const { ductID, indexNumber } = section;
  const { t } = useTranslation(['roomeditor']);

  const plenumDuctID = useSelector(
    (state) => selectPlenumById(state, props.plenumdata.plenumID ? props.plenumdata.plenumID : 0)?.ductID
  );
  const allDucts = useSelector(selectAllDucts);
  const ductAdapterEntities = useSelector(selectDuctAdapterEntities);
  const selectSectionsByConnection = React.useMemo(makeSelectSectionsByConnection, []);
  const sections = useSelector((state) =>
    selectSectionsByConnection(state, props.connection.valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0)
  );
  const prevAdapterDuctID = React.useMemo(() => {
    if (indexNumber !== 0) {
      const prevSection = sections.find((a) => a.indexNumber === indexNumber - 1);
      const adapter = ductAdapterEntities[prevSection?.ductAdapterID || 0];
      if (adapter) {
        return adapter.ductID1 === prevSection?.ductID ? adapter.ductID2 : adapter.ductID1;
      }
    }
    return 0;
  }, [sections, ductAdapterEntities, indexNumber]);

  const ducts = React.useMemo(
    () =>
      allDucts.filter(
        (duct) =>
          (indexNumber === 0 &&
            plenumDuctID === enDuctType.round92 &&
            (duct.ductID === enDuctType.round92 || duct.ductID === enDuctType.round75)) ||
          (indexNumber === 0 && plenumDuctID === enDuctType.oval && duct.ductID === enDuctType.oval) ||
          duct.ductID === prevAdapterDuctID
      ),
    [plenumDuctID, allDucts, indexNumber, prevAdapterDuctID]
  );

  React.useEffect(() => {
    if (ducts.length > 0) {
      if (!ductID || !ducts.find((a) => a.ductID === ductID)) {
        onChange(ducts[0].ductID, nameof<IDuct>('ductID'), '');
      }
    } else {
      onChange(undefined, nameof<IDuct>('ductID'), '');
    }
  }, [onChange, ductID, ducts]);

  return (
    <Selector
      fullWidth
      label={t('ductselector', 'duct')}
      helperText={t('ductselectorHelper', 'select duct')}
      required={true}
      name={nameof<ISection>('ductID')}
      value={ductID}
      onChange={onChange}
      items={ducts.map((duct) => (
        <MenuItem key={duct.ductID} value={duct.ductID.toString()}>
          {duct.name}
        </MenuItem>
      ))}
    />
  );
};

export default DuctSelect;
