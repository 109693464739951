import NumberInputField from 'components/controls/NumberInputField';
import { validateNoError } from 'components/controls/TextInputField';
import { useFlowRate } from 'components/controls/useFlowRate';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProjectState } from 'projectRedux/store';
import connectionStyles from './connectionStyles';

const normalizeAirFlowRate = (value: number | undefined, convertM3H: boolean) => {
  if (value && convertM3H) return value / 3.6;
  return value;
};

const prettierAirFlowRate = (value: number | undefined) => {
  if (value) return Math.round(value * 1000) / 1000;
  return value;
};

const DebietInputField = (props: {
  value: number | undefined;
  minAirflowRate: number;
  maxAirflowRate: number;
  warnAirflowRate: number;
  plenumMaxAirflowRate: number;
  plenumAirflowRate: number;
  convertM3H: boolean;
  name: string;
  label: string;
  onChange: (value: number, name: string, error: string) => void;
  required: boolean;
  debounce?: boolean;
  disabled?: boolean;
  style?: any;
  readOnly?: boolean;
  fullWidth?: boolean;
  className?: string;
}) => {
  const { onChange } = props;
  const { t } = useTranslation(['roomeditor']);
  const { FormatAirFlowRateUnit } = useFlowRate();
  const classes = connectionStyles();
  const experimental = useSelector(selectProjectState)?.activeProject?.experimental || false;

  const helperText = React.useMemo(
    () =>
      t('airflowrate_helper', 'between {{minAirflowRate}} and {{maxAirflowRate}}', {
        minAirflowRate: FormatAirFlowRateUnit(props.minAirflowRate),
        maxAirflowRate: FormatAirFlowRateUnit(props.maxAirflowRate),
      }),
    [props.minAirflowRate, props.maxAirflowRate, t, FormatAirFlowRateUnit]
  );

  const helperWarnText = React.useMemo(
    () =>
      t(
        'airflowrate_helperwarn',
        'N.B.: extraction/supply of air more than {{warnAirflowRate}} can have a negative effect on noise levels',
        {
          warnAirflowRate: FormatAirFlowRateUnit(props.warnAirflowRate),
        }
      ),
    [props.warnAirflowRate, t, FormatAirFlowRateUnit]
  );
  const validate = React.useCallback(
    (value: number) => {
      const val = normalizeAirFlowRate(value, props.convertM3H);
      if (!val) return validateNoError();
      if (val < props.minAirflowRate || val > props.maxAirflowRate) {
        if (experimental) {
          return {
            warn: helperText,
            error: '',
          };
        } else {
          return {
            warn: '',
            error: helperText,
          };
        }
      }
      if (props.plenumAirflowRate + val > props.plenumMaxAirflowRate + 0.1) {
        if (experimental) {
          return {
            error: '',
            warn: t(
              'airflowrate_maxplenum',
              'Total flow rate {{currentAirflowRate}} above maximum flow rate plenum {{maxAirflowRate}}',
              {
                currentAirflowRate: FormatAirFlowRateUnit(props.plenumAirflowRate + val),
                maxAirflowRate: FormatAirFlowRateUnit(props.plenumMaxAirflowRate),
              }
            ),
          };
        } else {
          return {
            warn: '',
            error: t(
              'airflowrate_maxplenum',
              'Total flow rate {{currentAirflowRate}} above maximum flow rate plenum {{maxAirflowRate}}',
              {
                currentAirflowRate: FormatAirFlowRateUnit(props.plenumAirflowRate + val),
                maxAirflowRate: FormatAirFlowRateUnit(props.plenumMaxAirflowRate),
              }
            ),
          };
        }
      }
      if (props.warnAirflowRate !== 0 && val > props.warnAirflowRate + 0.1) {
        return {
          warn: helperWarnText,
          error: '',
        };
      }
      return validateNoError();
    },
    [
      props.minAirflowRate,
      props.maxAirflowRate,
      props.warnAirflowRate,
      props.convertM3H,
      props.plenumAirflowRate,
      props.plenumMaxAirflowRate,
      t,
      helperText,
      helperWarnText,
      FormatAirFlowRateUnit,
      experimental,
    ]
  );

  return (
    <NumberInputField
      value={React.useMemo(() => {
        return props.value && props.convertM3H ? prettierAirFlowRate(props.value * 3.6) : props.value;
      }, [props.convertM3H, props.value])}
      name={props.name}
      validate={validate}
      label={props.label}
      helperText={helperText}
      onChange={React.useCallback(
        (value: number | undefined, name: string, error: string) => {
          if (!value) return '';
          const val = normalizeAirFlowRate(value, props.convertM3H);
          if (val && !error) {
            onChange(val, name, error);
          }
        },
        [onChange, props.convertM3H]
      )}
      required={props.required}
      disabled={props.disabled}
      debounce
      style={props.style}
      readOnly={props.readOnly}
      inputClassName={classes.inputClassName}
      type="decimal"
      fullWidth={props.fullWidth}
      className={props.className}
    />
  );
};

export default DebietInputField;
