import * as React from 'react';
import {
  DUCT_MAX_LENGTH,
  ISection,
  IValveConnection,
  SECTIONS_AS_PLENUMCONNECTION_0,
} from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import connectionStyles from './controls/connectionStyles';
import clsx from 'clsx';
import { nameof } from 'application/shared/nameof';
import LengthInputField from './controls/LengthInputField';
import DuctSelect from './controls/DuctSelect';
import { useTranslation } from 'react-i18next';
import { SetSectionValue } from 'application/project/redux/thunks/SetSectionValue';
import { useDispatch, useSelector } from 'react-redux';
import { sectionKey } from 'application/project/redux/adapters/sectionAdapter';
import { makeSelectSectionsByConnection } from 'application/project/redux/selectors/sectionSelectors';

const PartDuctAndLength = (props: { plenumdata: IPlenumData; connection: IValveConnection; section: ISection }) => {
  const classes = connectionStyles();
  const dispatch = useDispatch();
  const { section } = props;
  const { t } = useTranslation(['roomeditor']);

  const onChange = React.useCallback(
    (value: number | undefined, field: string, error: string) =>
      dispatch(SetSectionValue(section.valveConnectionKey, sectionKey(section), field, value) as any),
    [dispatch, section]
  );
  const selectSectionsByConnection = React.useMemo(makeSelectSectionsByConnection, []);
  const sections = useSelector((state) =>
    selectSectionsByConnection(state, props.connection.valveConnectionKey, SECTIONS_AS_PLENUMCONNECTION_0)
  );
  const maxLength = React.useMemo(() => {
    const totalLengte = sections
      .filter((a) => a.indexNumber !== section.indexNumber)
      .reduce((val, sec) => val + (sec.ductLenght || 0), 0);
    return Math.max(DUCT_MAX_LENGTH - totalLengte, 0);
  }, [sections, section]);

  return (
    <div className={clsx(classes.partBlock)}>
      <div className={clsx(classes.halfLeft)}>
        <DuctSelect
          plenumdata={props.plenumdata}
          connection={props.connection}
          section={props.section}
          onChange={onChange}
        />
      </div>
      <div className={clsx(classes.halfRight)}>
        <LengthInputField
          required={true}
          label={t('ductlength', 'length')}
          maxLength={maxLength}
          name={nameof<ISection>('ductLenght')}
          value={props.section.ductLenght}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default PartDuctAndLength;
