import * as React from 'react';
import { useStyles } from './styles';
import { IProject } from 'application/project/api/Project';
import { useSelector } from 'react-redux';
import { selectCommonState } from 'projectRedux/store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const ApplicationBar = (props: {
  project?: IProject;
  handleDrawerToggle: () => any;
  onPrev: () => any;
  onNext: () => any;
  canPrev: boolean;
  canNext: boolean;
}) => {
  const classes = useStyles();
  const commonState = useSelector((state) => selectCommonState(state));
  const { t } = useTranslation(['editortabs']);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.menuToolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {commonState.pageTitle || ''}
        </Typography>
        <div style={{ right: 0, position: 'absolute' }}>
          <IconButton disabled={!props.canPrev} onClick={props.onPrev} title={t('previouspage', 'previous page')}>
            <ChevronLeftIcon fontSize="large" style={{ color: props.canPrev ? 'white' : undefined }} />
          </IconButton>
          <IconButton disabled={!props.canNext} onClick={props.onNext} title={t('nextpage', 'next page')}>
            <ChevronRightIcon fontSize="large" style={{ color: props.canNext ? 'white' : undefined }} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ApplicationBar;
