import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const connectionStyles = makeStyles((theme: Theme) => ({
  root: {},

  part: {
    margin: 5,
    borderColor: '#aa0000',
    borderStyle: 'solid',
    borderWidth: 1,
  },

  checkbox: {
    marginTop: 16,
  },

  flexContainer: {
    display: 'flex',
  },

  flexGrowHor: {
    flexGrow: 1,
  },

  floatRight: {
    float: 'right',
  },

  halfLeft: {
    width: 'calc(50% - 4px)',
    float: 'left',
  },
  halfRight: {
    width: 'calc(50% - 4px)',
    float: 'right',
  },

  partBlock: {
    overflow: 'auto',
    paddingLeft: 4,
    paddingRight: 4,
  },
  partComplexBlock: {
    overflow: 'auto',
  },

  valveImage: {
    maxWidth: 100,
  },

  connectionRoot: {
    position: 'relative',
    '& .restrictor': {
      '& .MuiInputBase-formControl': {
        backgroundColor: theme.palette.grey[300],
        Color: theme.palette.text.primary,
        fontWeight: 600,
      },
    },
    borderStyle: 'solid',
    borderColor: '#fff',
    borderWidth: 1,
    '&.actief': {
      '&.connectionError': { borderColor: theme.palette.error.main },
      '&.ConnectionDirtydirty': { borderColor: theme.palette.secondary.main },
      '&.ConnectionDirtysaving': { borderColor: theme.palette.success.main },
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      '& .MuiInputBase-formControl': {
        backgroundColor: 'white',
      },
      '& .MuiFilledInput-underline::after': {
        borderColor: theme.palette.secondary.main,
      },
      '& .CheckBoxerLabel': {
        color: 'white',
      },
      '& .MuiCheckbox-root': {
        color: 'white',
      },
      '& .MuiCheckbox-root.Mui-checked': {
        color: theme.palette.secondary.main,
      },
      '& .MuiFormHelperText-root': {
        color: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .valvewarn': {
        color: 'white',
      },
    },
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 60,
    height: 60,
  },
  deleteIcon: {
    width: 40,
    height: 40,
  },

  elbow: {
    minWidth: 60,
  },

  inputClassName: {},

  tableStyle: { width: '100%', tableLayout: 'fixed' },
  tableCell3: { paddingLeft: 0, paddingRight: 0, width: '33%', verticalAlign: 'top' },
  tableCell2: { paddingLeft: 0, paddingRight: 0, width: '50%', verticalAlign: 'top' },
}));

export default connectionStyles;
