import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ISettings } from 'application/editcomp/api/Settings';
import React from 'react';
import { IGridStatePlaceHolder } from '../../controls/hooks/useGridStateData';
import { useKeyboardNavigation } from '../../controls/hooks/useKeyboardNavigation';
import { datatype } from '../../controls/input/GridInput';
import { GridInputTd } from '../../controls/table/GridInputTd';
import useTableProps from '../../controls/table/properties/useTableProps';
import Table from '../../controls/table/Table';
import Td from '../../controls/table/Td';
import Th from '../../controls/table/Th';
import { IGridStateData } from '../Settings';
import { IRenderRowCallBacks, RenderRow } from './RenderRow';
import { nameof } from 'application/shared/nameof';

const styles = makeStyles((theme: Theme) => ({
  cell: {
    minWidth: 50,
  },
}));

const SettingsGridTable = (props: {
  state: IGridStatePlaceHolder<IGridStateData>;
  settings: ISettings[];
  saveSetting: (setting: ISettings) => any;
}) => {
  const keyboardNavigation = useKeyboardNavigation({
    rowdatas: props.settings,
    getFieldID: (item: ISettings) => item.settingID,
  });

  const classes = styles();
  const [stickyRowTop, setStickyRowHeight] = useTableProps(3, 115, 3, 0);

  const headerRow: IRenderRowCallBacks = {
    name: () => (
      <Th key="name" stickyRowTop={stickyRowTop[0]} OnHeightChanged={(height: number) => setStickyRowHeight(0, height)}>
        setting name
      </Th>
    ),
    value: () => (
      <Th key="value" stickyRowTop={stickyRowTop[0]}>
        value
      </Th>
    ),
    encrypted: () => (
      <Th key="encrypted" stickyRowTop={stickyRowTop[0]}>
        encrypted
      </Th>
    ),
  };

  const dataRow: IRenderRowCallBacks = {
    name: (setting: ISettings) => <Td key="name">{setting.settingID}</Td>,
    value: (setting: ISettings) => (
      <GridInputTd
        key="value"
        tdClassName={classes.cell}
        value={setting.value}
        row={setting}
        datatype={datatype.string}
        onCommit={(row: any, name: string, value: any) => props.saveSetting({ ...setting, value: value })}
        name={nameof('value')}
        keyboardNavigation={keyboardNavigation}
      />
    ),
    encrypted: (setting: ISettings) => (
      <Td key="encrypted" textAlign="center">
        {setting.encrypted ? 'JA' : 'nee'}
      </Td>
    ),
  };

  const filteredRows = React.useMemo(
    () => props.settings /*.filter(
        (a) =>
          (canDuplex === undefined || a.canDuplex === canDuplex) &&
          (valveConnectionID === 0 || a.valveConnectionID === valveConnectionID) &&
          (ductID === 0 || a.ductID === ductID) &&
          (valveDiameterID === 0 || a.valveDiameterID === valveDiameterID)
      )*/,
    [props.settings]
  );

  return (
    <Table>
      <thead>
        <tr>{RenderRow(props.state, props.settings.length, headerRow, undefined)}</tr>
      </thead>
      <tbody>
        {filteredRows.map((setting) => (
          <tr key={setting.settingID}>{RenderRow(props.state, props.settings.length, dataRow, setting)}</tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SettingsGridTable;
