import RegularPage from 'components/controls/RegularPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectCommonState } from 'projectRedux/store';
import EditComponentItems, { linkitem } from './MenuKeuzes';

const MapItems = (props: { items: linkitem[] }) => {
  const location = useLocation();
  const pathname = location.pathname + (location.pathname.endsWith('/') ? '' : '/');
  const commonState = useSelector(selectCommonState);
  return (
    <div>
      {props.items.map((item) =>
        commonState.user?.roles.includes(item.role) ? (
          <div key={item.to}>
            <Link to={pathname + item.to}>{item.title}</Link>
          </div>
        ) : null
      )}
    </div>
  );
};
const SelectEditComponent = () => {
  return (
    <RegularPage title="Edit Components">
      <React.Fragment>
        <MapItems items={EditComponentItems} />
      </React.Fragment>
    </RegularPage>
  );
};

export default SelectEditComponent;
