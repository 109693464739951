import * as React from 'react';
import { IValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import { useSelector } from 'react-redux';
import { selectComponentState } from 'projectRedux/store';
import { projectState } from 'application/project/redux/projectState';
import getValveConnectionError from 'application/project/validate/getValveConnectionError';

const useIsConnectionValid = (plenumData: IPlenumData) => {
  const componentState = useSelector(selectComponentState);
  const getConnectionError = React.useCallback(
    (connection: IValveConnection, projectState: projectState) => {
      return getValveConnectionError(connection, plenumData, projectState, componentState);
    },
    [plenumData, componentState]
  );

  return getConnectionError;
};

export default useIsConnectionValid;
