import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: drawerWidth,
    },
    boxShadow: 'unset',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuToolbar: {
    borderBottomColor: theme.palette.secondary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 4,
  },
  List: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },

  menu: {
    textDecoration: 'none',
  },

  logo: {
    maxHeight: 64,
  },

  logoHost: {
    textAlign: 'center',
  },

  menuIcon: {
    minWidth: '40px !important',
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'inline',
    '&.active': {
      color: theme.palette.primary.contrastText,
    },
  },
  menuImage: {
    display: 'block',
    margin: 'auto',
  },
  menuItem: {
    paddingLeft: '1px !important',
    paddingRight: theme.spacing(1),
    paddingTop: '2px !important',
    borderBottomColor: '#ddd',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    color: theme.palette.primary.main,
    '&.active': {
      color: theme.palette.secondary.main,
    },
  },
  menuText: {
    '&.noIcon': {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1),
      '&.active': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));
