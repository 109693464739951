import { DUCT_WARN_LENGTH } from 'application/project/api/Connection';
import NumberInputField from 'components/controls/NumberInputField';
import { validateNoError } from 'components/controls/TextInputField';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProjectState } from 'projectRedux/store';

const LengthInputField = (props: {
  value: number | undefined;
  name: string;
  label: string;
  maxLength: number;
  onChange: (value: number, name: string, error: string) => void;
  required: boolean;
  disabled?: boolean;
  style?: any;
  readOnly?: boolean;
  className?: string;
}) => {
  const { onChange } = props;
  const { t } = useTranslation(['roomeditor']);
  const experimental = useSelector(selectProjectState)?.activeProject?.experimental || false;

  const helperText = React.useMemo(
    () => t('lenghtInputHelper', 'max {{maxLength}} m', { maxLength: props.maxLength }),
    [props.maxLength, t]
  );
  const helperWarnText = React.useMemo(
    () =>
      t(
        'lenghtInputWarn',
        'N.B.: a duct length greater than {{maxLength}} m can have a negative effect on noise levels',
        {
          maxLength: DUCT_WARN_LENGTH,
        }
      ),
    [t]
  );
  const validate = React.useCallback(
    (val: number) => {
      if (val <= 0 || (val > props.maxLength && !experimental)) return { error: helperText, warn: '' };
      if (val > DUCT_WARN_LENGTH + 0.1) {
        return {
          warn: helperWarnText,
          error: '',
        };
      }
      return validateNoError();
    },
    [props.maxLength, helperText, helperWarnText, experimental]
  );

  return (
    <NumberInputField
      value={props.value}
      validate={validate}
      name={props.name}
      label={props.label}
      debounce={true}
      helperText={helperText}
      onChange={React.useCallback(
        (value: number | undefined, name: string, error: string) => {
          if (value) {
            onChange(value, name, error);
          }
        },
        [onChange]
      )}
      required={props.required}
      disabled={props.disabled}
      style={props.style}
      readOnly={props.readOnly}
      type="decimal"
      fullWidth
      className={props.className}
    />
  );
};

export default LengthInputField;
